<template>
    <div style="overflow-x:auto !important">
        <div class="my_fontSize_1Point5 my_color_00338e my_fontWeight_600 ZAfont_family">{{$t('3027')}}</div>
        <div style="display: grid; grid-template-columns: 40px auto; align-items:center">
            <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><i class="fas fa-plus"></i></div>
            <div>
                <span class="my_fontSize_1Point5 my_color_00338e ZAfont_family">{{$t('3203')}} </span>
                <span v-if="((elevatorMotor_Machine_Filter_Data!=null)&&(elevatorMotor_Machine_Selection_Shown==true))">
                    <span v-if="elevatorMotor_Machine_Filter_Shown==true" @click="elevatorMotor_Machine_Filter_Shown=false" class="fas fa-filter my_color_00338e my_fontSize_1Point3"></span>
                    <span v-if="elevatorMotor_Machine_Filter_Shown==false" @click="elevatorMotor_Machine_Filter_Shown=true" class="fal fa-filter my_color_00338e my_fontSize_1Point3"></span>
                </span>
                <button v-if="elevatorMotor_Machine_Selection_Shown==false" @click="elevatorMotor_Machine_Selection_Shown=true" class="btn my_btn_changeSelection">{{$t('3182')}}</button>
            </div>
            <div style="grid-column:2 / span 1">
                <div v-bind:class="{my_grid_filter: ((elevatorMotor_Machine_Filter_Data!=null) && (elevatorMotor_Machine_Selection_Shown==true) && (elevatorMotor_Machine_Filter_Shown==true))}">
                    <div v-if="((elevatorMotor_Machine_Filter_Data!=null) && (elevatorMotor_Machine_Selection_Shown==true) && (elevatorMotor_Machine_Filter_Shown==true))">
                        <div class="my_filter">
                            <ul class="my_elevatorMotor_Machine_filter">
                                <li class="ZAfont_family ZAfont_size" style="width:100%; background-color:#00338e; color:#ffffff">{{$t('3092')}}</li>
                            </ul>
                            <div style="margin-top:20px">
                                <div v-if="((elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection.length > 1)||(lowestNominalRotationSpeedPerMachineTractionSheaveCombination_Available==true)||(elevatorMotor_Machine_ElectricalUtilization_Maximum > elevatorMotor_Machine_ElectricalUtilization_LowestPossibleMaximum)||(elevatorMotor_Machine_MechanicalUtilization_Maximum > elevatorMotor_Machine_MechanicalUtilization_LowestPossibleMaximum))" style="margin-bottom:20px">
                                    <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e">{{$t('3028')}}</div>
                                    <div v-if="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection.length > 1">
                                        <div class="my_fontSize_1 my_fontWeight_600 ZAfont_family">{{$t('3029')}}</div>
                                        <hr style="margin-top:0; margin-bottom:0" />
                                        <ul style="list-style:none; padding-top:5px; padding-left:0px; padding-right:0px; padding-bottom:0px; margin:0" class="ZAfont_family ZAfont_size">
                                            <li v-for="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series in elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection">
                                                <div style="display: grid; grid-template-columns: 22px auto;">
                                                    <span>
                                                        <input type="checkbox" @input="change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection(elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series.text)" v-bind:checked="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series.selected" />
                                                    </span>
                                                    <span v-if="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series.textKey==null"> {{elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series.text}}</span>
                                                    <span v-else> {{$t(elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series.textKey)}}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-if="elevatorMotor_SafetyBrake_Available1==true">
                                        <div style="display:grid; grid-template-columns:22px auto;">
                                            <span>
                                                <input type="checkbox" v-model="elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake" />
                                            </span>
                                            <span class="ZAfont_family ZAfont_size" v-html="$t('3359')"></span>
                                        </div>
                                    </div>
                                    <div v-if="elevatorMotor_AutomationProject==true">
                                        <div style="display:grid; grid-template-columns:22px auto;">
                                            <span>
                                                <input type="checkbox" v-model="elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject" />
                                            </span>
                                            <span class="ZAfont_family ZAfont_size">APro</span>
                                        </div>
                                    </div>
                                    <div v-if="elevatorMotor_ZAframe==true" style="margin-top:10px">
                                        <div class="my_fontSize_1 my_fontWeight_600 ZAfont_family">{{$t('1025')}}</div>
                                        <div style="display:grid; grid-template-columns:22px auto;">
                                            <span>
                                                <input type="checkbox" v-model="elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe" />
                                            </span>
                                            <span class="ZAfont_family ZAfont_size">{{$t('3417')}}</span>
                                        </div>
                                    </div>
                                    <div v-if="((elevatorMotor_TractionSheave_Diameter_Minimum > 0) && (elevatorMotor_TractionSheave_Diameter_Maximum > 0))" style="margin-top:10px">
                                        <div class="my_fontSize_1 my_fontWeight_600 ZAfont_family">{{$t('1028')}}</div>
                                        <div class="my_input-group">
                                            <select tabindex="17" v-model="elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum" class="my_select" style="width:100%">
                                                <option value="null">{{$t('3174')}}</option>
                                                <option v-for="tractionSheave_Filter_selected in elevatorMotor_Machine_FilterCriterion_tractionSheaveDiameter_Selection">{{tractionSheave_Filter_selected}}</option>
                                            </select>
                                            <div class="my_input-group_unit ZAfont_family">mm</div>
                                        </div>
                                    </div>
                                    <div v-if="lowestNominalRotationSpeedPerMachineTractionSheaveCombination_Available==true" style="margin-top:10px">
                                        <div class="my_fontSize_1 my_fontWeight_600 ZAfont_family">{{$t('3205')}}</div>
                                        <div style="display:grid; grid-template-columns:22px auto;">
                                            <span>
                                                <input type="checkbox" v-model="elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination" />
                                            </span>
                                            <span class="ZAfont_family ZAfont_size" v-html="$t('3030')"></span>
                                        </div>
                                    </div>
                                    <div v-if="elevatorMotor_Machine_ElectricalUtilization_Maximum > elevatorMotor_Machine_ElectricalUtilization_LowestPossibleMaximum" style="margin-top:20px">
                                        <div class="my_fontSize_1 my_fontWeight_600 ZAfont_family">{{$t('3035')}}</div>
                                        <div>
                                            <div class="my_input-group">
                                                <input v-model="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum" v-bind:class="{my_input_border_at_invalidInput: elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_ValidationResult.invalid==true}" class="my_input" />
                                                <div class="ZAfont_family my_input-group_unit">%</div>
                                            </div>
                                            <div v-if="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_ValidationResult.invalid==false" class="ZAfont_family">{{$t('3243', {0: elevatorMotor_Machine_ElectricalUtilization_LowestPossibleMaximum, 1: elevatorMotor_Machine_ElectricalUtilization_Maximum})}}</div>
                                            <InvalidityReasons v-if="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_ValidationResult.invalid==true" v-bind:validationResult="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_ValidationResult"></InvalidityReasons>
                                        </div>
                                    </div>
                                    <div v-if="elevatorMotor_Machine_MechanicalUtilization_Maximum > elevatorMotor_Machine_MechanicalUtilization_LowestPossibleMaximum" style="margin-top:10px">
                                        <div class="my_fontSize_1 my_fontWeight_600 ZAfont_family">{{$t('3036')}}</div>
                                        <div>
                                            <div class="my_input-group">
                                                <input v-model="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum" v-bind:class="{my_input_border_at_invalidInput: elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_ValidationResult.invalid==true}" class="my_input" />
                                                <div class="ZAfont_family my_input-group_unit">%</div>
                                            </div>
                                            <div v-if="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_ValidationResult.invalid==false" class="ZAfont_family">{{$t('3243', {0: elevatorMotor_Machine_MechanicalUtilization_LowestPossibleMaximum, 1: elevatorMotor_Machine_MechanicalUtilization_Maximum})}}</div>
                                            <InvalidityReasons v-if="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_ValidationResult.invalid==true" v-bind:validationResult="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_ValidationResult"></InvalidityReasons>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="elevatorMotor_TemperatureMonitoringByPT100_Available==true" style="margin-bottom:20px">
                                    <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e">{{$t('3354')}}</div>
                                    <div style="display:grid; grid-template-columns:22px auto;">
                                        <span>
                                            <input type="checkbox" v-model="elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100" />
                                        </span>
                                        <span class="ZAfont_family ZAfont_size" v-html="$t('3040')"></span>
                                    </div>
                                </div>
                                <div v-if="elevatorMotor_ForcedVentilation_AvailableAndOrNecessary==true" style="margin-bottom:20px">
                                    <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e">{{$t('298')}}</div>
                                    <div style="display:grid; grid-template-columns:22px auto;">
                                        <span>
                                            <input type="checkbox" v-model="elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation" />
                                        </span>
                                        <span class="ZAfont_family ZAfont_size">{{$t('3041')}}</span>
                                    </div>
                                </div>
                                <div v-if="elevatorMotor_Brake_MechanicalRelease_Available1==true">
                                    <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e">{{$t('3260')}}</div>
                                    <div style="display:grid; grid-template-columns:22px auto;">
                                        <span>
                                            <input type="checkbox" v-model="elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease" />
                                        </span>
                                        <span class="ZAfont_family ZAfont_size" v-html="$t('3042')"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-if="elevatorMotor_Machine_Selection_Shown==true">
                            <div v-if="elevatorMotor_Machine_Selection_Filtered==true">
                                <button @click="remove_allElementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria()" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#00338e">
                                    <span class="ZAfont_family ZAfont_size" style="color:#ffffff">{{$t('3066')}}</span>
                                </button>
                                <span v-for="filterCriterion in arrayOf_active_elevatorMotor_Machine_FilterCriteria">
                                    <button v-if="(filterCriterion.filterBy == 'elevatorMotor_Machine_Series') && (filterCriterion.data == 'ZAtopx')" @click="change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection('ZAtopx')" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e">ZAtopx</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="(filterCriterion.filterBy == 'elevatorMotor_Machine_Series') && (filterCriterion.data == 'ZAtop')" @click="change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection('ZAtop')" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e">ZAtop</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="(filterCriterion.filterBy == 'elevatorMotor_Machine_Series') && (filterCriterion.data == 'ZAsyn')" @click="change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection('ZAsyn')" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e">ZAsyn</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="(filterCriterion.filterBy == 'elevatorMotor_Machine_Series') && (filterCriterion.data == 'ZAdisc')" @click="change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection('ZAdisc')" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e">ZAdisc</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="(filterCriterion.filterBy == 'elevatorMotor_Machine_Series') && (filterCriterion.data == 'Geared machines')" @click="change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection('Geared machines')" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e">{{$t('3032')}}</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="filterCriterion.filterBy == 'lowestNominalRotationSpeedPerMachineTractionSheaveCombination'" @click="addOrRemove_elementAt_arrayof_active_elevatorMotor_Machine_FilterCriteria({ filterBy: 'lowestNominalRotationSpeedPerMachineTractionSheaveCombination' })" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e">{{$t('3044')}}</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="filterCriterion.filterBy == 'elevatorMotor_Machine_ElectricalUtilization_Maximum'" @click="remove_elevatorMotor_Machine_ElectricalUtilization_Maximum_and_MechanicalUtilization_Maximum_at_arrayof_active_elevatorMotor_Machine_FilterCriteria({ filterBy: 'elevatorMotor_Machine_ElectricalUtilization_Maximum'})" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e" style="font-weight:500">{{$t('3035')}}:</span>
                                        <span class="ZAfont_family ZAfont_size my_color_00338e"> {{elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum}} %</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="filterCriterion.filterBy == 'elevatorMotor_Machine_MechanicalUtilization_Maximum'" @click="remove_elevatorMotor_Machine_ElectricalUtilization_Maximum_and_MechanicalUtilization_Maximum_at_arrayof_active_elevatorMotor_Machine_FilterCriteria({ filterBy: 'elevatorMotor_Machine_MechanicalUtilization_Maximum'})" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e" style="font-weight:500">{{$t('3036')}}:</span>
                                        <span class="ZAfont_family ZAfont_size my_color_00338e"> {{elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum}} %</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="filterCriterion.filterBy == 'elevatorMotor_TractionSheave_Diameter'" @click="remove_elevatorMotor_Machine_TractionSheave_Diameter_at_arrayof_active_elevatorMotor_Machine_FilterCriteria()" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e" style="font-weight:500">{{$t('36')}}:</span>
                                        <span class="ZAfont_family ZAfont_size my_color_00338e" v-if="elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum==elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum"> {{elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum}} mm</span>
                                        <span class="ZAfont_family ZAfont_size my_color_00338e" v-if="elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum!=elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum"> {{elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum}}-{{elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum}} mm</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="filterCriterion.filterBy == 'elevatorMotor_Brake_MechanicalRelease'" @click="addOrRemove_elementAt_arrayof_active_elevatorMotor_Machine_FilterCriteria({ filterBy: 'elevatorMotor_Brake_MechanicalRelease' })" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e">{{$t('3045')}}</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="filterCriterion.filterBy == 'elevatorMotor_TemperatureMonitoringByPT100'" @click="addOrRemove_elementAt_arrayof_active_elevatorMotor_Machine_FilterCriteria({ 'filterBy': 'elevatorMotor_TemperatureMonitoringByPT100' })" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e">{{$t('3070')}}</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="filterCriterion.filterBy == 'elevatorMotor_ForcedVentilation'" @click="addOrRemove_elementAt_arrayof_active_elevatorMotor_Machine_FilterCriteria({ 'filterBy': 'elevatorMotor_ForcedVentilation' })" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e">{{$t('298')}}</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                    <button v-if="filterCriterion.filterBy == 'elevatorMotor_ZAframe'" @click="addOrRemove_elementAt_arrayof_active_elevatorMotor_Machine_FilterCriteria({ 'filterBy': 'elevatorMotor_ZAframe' })" type="button" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                        <span class="ZAfont_family ZAfont_size my_color_00338e">{{$t('3417')}}</span>
                                        <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                                    </button>
                                </span>
                            </div>
                            <div v-if="elevatorMotor_Machine_Selection.length > 0">
                                <div class="ZAfont_family my_fontSize_1Point1 my_font-weight_700">{{$t('3266')}}</div>
                                <div class="ZAfont_family ZAfont_size">
                                    <div v-if="elevatorMotor_Machine_Selection_Filtered==false">
                                        <div v-if="elevatorMotor_Machine_Selection_fullLength==1">{{$t('3046', {show: 1, max: 1})}}</div>
                                        <div v-if="((elevatorMotor_Machine_Selection_fullLength>1) && (elevatorMotor_Machine_Selection_fullLength<=5))">{{$t('3046', {show: elevatorMotor_Machine_Selection_fullLength, max: elevatorMotor_Machine_Selection_fullLength})}}</div>
                                        <div v-if="((elevatorMotor_Machine_Selection_fullLength>5) && (elevatorMotor_Machine_Selection_lastIndex<elevatorMotor_Machine_Selection_fullLength))">{{$t('3046', {show: '1 - ' + elevatorMotor_Machine_Selection_lastIndex, max: elevatorMotor_Machine_Selection_fullLength})}}</div>
                                        <div v-if="((elevatorMotor_Machine_Selection_fullLength>5) && (elevatorMotor_Machine_Selection_lastIndex>=elevatorMotor_Machine_Selection_fullLength))">{{$t('3046', {show: '1 - ' + elevatorMotor_Machine_Selection_fullLength, max: elevatorMotor_Machine_Selection_fullLength})}}</div>
                                    </div>
                                    <div v-if="elevatorMotor_Machine_Selection_Filtered==true">
                                        <div v-if="filtered_elevatorMotor_Machine_Selection.length == elevatorMotor_Machine_Selection_fullLength">
                                            <div v-if="elevatorMotor_Machine_Selection_fullLength==1">1 von 1 Antrieben</div>
                                            <div v-if="((elevatorMotor_Machine_Selection_fullLength>1) && (elevatorMotor_Machine_Selection_fullLength<=5))">{{$t('3046', {show: elevatorMotor_Machine_Selection_fullLength, max: elevatorMotor_Machine_Selection_fullLength})}}</div>
                                            <div v-if="((elevatorMotor_Machine_Selection_fullLength>5) && (elevatorMotor_Machine_Selection_lastIndex < elevatorMotor_Machine_Selection_fullLength))">{{$t('3046', {show: '1 - ' + elevatorMotor_Machine_Selection_lastIndex, max: elevatorMotor_Machine_Selection_fullLength})}}</div>
                                            <div v-if="((elevatorMotor_Machine_Selection_fullLength>5) && (elevatorMotor_Machine_Selection_lastIndex >= elevatorMotor_Machine_Selection_fullLength))">{{$t('3046', {show: '1 - ' + elevatorMotor_Machine_Selection_fullLength, max: elevatorMotor_Machine_Selection_fullLength})}}</div>
                                        </div>
                                        <div v-if="filtered_elevatorMotor_Machine_Selection.length < elevatorMotor_Machine_Selection_fullLength">
                                            <div v-if="filtered_elevatorMotor_Machine_Selection.length==1">{{$t('3046', {show: 1, max: 1})}} | {{$t('3047', {total: elevatorMotor_Machine_Selection_fullLength})}}</div>
                                            <div v-if="((filtered_elevatorMotor_Machine_Selection.length>1) && (filtered_elevatorMotor_Machine_Selection.length<=5))">{{$t('3046', {show: filtered_elevatorMotor_Machine_Selection.length, max: filtered_elevatorMotor_Machine_Selection.length})}} | {{$t('3047', {total: elevatorMotor_Machine_Selection_fullLength})}}</div>
                                            <div v-if="((filtered_elevatorMotor_Machine_Selection.length>5) && (elevatorMotor_Machine_Selection_lastIndex<filtered_elevatorMotor_Machine_Selection.length))">{{$t('3046', {show: '1 - ' + elevatorMotor_Machine_Selection_lastIndex, max: filtered_elevatorMotor_Machine_Selection.length})}} |  {{$t('3047', {total: elevatorMotor_Machine_Selection_fullLength})}}</div>
                                            <div v-if="((filtered_elevatorMotor_Machine_Selection.length>5) && (elevatorMotor_Machine_Selection_lastIndex>=filtered_elevatorMotor_Machine_Selection.length))">{{$t('3102', {show: '1 - ' + filtered_elevatorMotor_Machine_Selection.length, max: filtered_elevatorMotor_Machine_Selection.length})}} | {{$t('3047', {total: elevatorMotor_Machine_Selection_fullLength})}}</div>
                                        </div>
                                    </div>
                                </div>
                                <ul class="my_elevatorMotor_Machine">
                                    <li v-for="elevatorMotor_Machine in elevatorMotor_Machine_Selection" v-bind:class="{selected: elevatorMotor_Machine.selected==true}">
                                        <div class="my_div_width1500" style="display:grid; grid-template-columns: auto 280px; grid-template-rows:100%">
                                            <div style="cursor: pointer; border: 1px solid #ced2d8;display:grid;" @click="change_Selected_at_elevatorMotor_Machine_Selection(elevatorMotor_Machine)">
                                                <div style="display: grid; grid-template-columns: auto auto auto auto auto auto auto; padding: 3px; overflow: auto">
                                                    <div style="align-self:center; grid-column: 1 / span 7">
                                                        <div class="my_fontWeight_600 my_fontSize_1Point1 ZAfont_family" :style='{color:customizedColor(elevatorMotor_Machine.customized)}'>
                                                            <span>{{elevatorMotor_Machine.elevatorMotor_Machine.typeDesignation}}</span>
                                                            <span v-if="elevatorMotor_Machine.customized===true"> ({{elevatorMotor_Machine.customerName}})</span>

                                                            <!--<span class="hover-text2">
                                                                <span>{{$t('3240')}}</span>
                                                                <span class="tooltip-text2" id="tooltip_right" style="overflow: visible !important;width:auto">
                                                                    <span style="display: grid; grid-template-columns: 200px 100px; padding: 9px; overflow: visible; width: auto">
                                                                        <span style="grid-column: 1/1; text-align: left">{{$t('1326')}}:</span><span style="text-align:right">{{elevatorMotor_Machine.elevatorMotor_Machine.axleLoad}} kg</span>
                                                                        <span style="grid-column: 1/1; text-align: left">{{$t('3272')}}:</span><span style="text-align:right">{{elevatorMotor_Machine.elevatorMotor_Machine.operatingRotationSpeed}} rpm</span>
                                                                        <span style="grid-column: 1/1; text-align: left">{{$t('3049')}}:</span><span style="text-align:right">{{ValueDecimalSeparator(elevatorMotor_Machine.elevatorMotor_Machine.operatingCurrent)}} A</span>
                                                                        <span style="grid-column: 1/1; text-align: left">{{$t('3051')}}:</span><span style="text-align:right">{{elevatorMotor_Machine.elevatorMotor_Machine.operatingTorque}} Nm</span>
                                                                        <span style="grid-column: 1/1; text-align: left">{{$t('3052')}}:</span><span style="text-align:right">{{ValueDecimalSeparator(elevatorMotor_Machine.elevatorMotor_Machine.mechanicalUtilization)}} %</span>
                                                                    </span>
                                                                </span>
                                                            </span>-->
                                                            <span class="has-tooltipdetails">
                                                                Details
                                                                <span class="tooltipdetails-wrapper">
                                                                    <span class="tooltipdetails">
                                                                        <span style="display: grid; grid-template-columns: 200px 100px; padding: 9px; overflow: visible; width: auto">
                                                                            <span v-if="elevatorMotor_Machine.elevatorMotor_Machine.design != 'gearless'" style="grid-column: 1/1; text-align: left">{{$t('3048')}}:</span><span v-if="elevatorMotor_Machine.elevatorMotor_Machine.design != 'gearless'" style="text-align:right">{{elevatorMotor_Machine.elevatorMotor_Machine.maximumAxleLoad}} kg</span>
                                                                            <span style="grid-column: 1/1; text-align: left">{{$t('1326')}}:</span><span style="text-align:right">{{elevatorMotor_Machine.elevatorMotor_Machine.axleLoad}} kg</span>
                                                                            <span v-if="elevatorMotor_Machine.elevatorMotor_Machine.design == 'gearless'" style="grid-column: 1/1; text-align: left">{{$t('3272')}}:</span><span v-if="elevatorMotor_Machine.elevatorMotor_Machine.design == 'gearless'" style="text-align:right">{{elevatorMotor_Machine.elevatorMotor_Machine.operatingRotationSpeed}} rpm</span>
                                                                            <span v-if="elevatorMotor_Machine.elevatorMotor_Machine.design != 'gearless'" style="grid-column: 1/1; text-align: left">{{$t('1091')}}:</span><span v-if="elevatorMotor_Machine.elevatorMotor_Machine.design != 'gearless'" style="text-align:right">{{elevatorMotor_Machine.elevatorMotor_Machine.nominalRotationSpeed}} rpm</span>
                                                                            <span style="grid-column: 1/1; text-align: left">{{$t('3049')}}:</span><span style="text-align:right">{{ValueDecimalSeparator(elevatorMotor_Machine.elevatorMotor_Machine.operatingCurrent)}} A</span>
                                                                            <span style="grid-column: 1/1; text-align: left">{{$t('3051')}}:</span><span style="text-align:right">{{elevatorMotor_Machine.elevatorMotor_Machine.operatingTorque}} Nm</span>
                                                                            <span style="grid-column: 1/1; text-align: left">{{$t('3050')}}:</span><span style="text-align:right">{{ValueDecimalSeparator(elevatorMotor_Machine.elevatorMotor_Machine.electricalUtilization)}} %</span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="width1500" style="grid-column: 1 / span 1; grid-row: 2 / span 2;width:75px">
                                                        <Images :imageFilePath="elevatorMotor_Machine.imagePath" :maxImageHeight="45" :maxImageWidth="75"></Images>
                                                    </div>
                                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{elevatorMotor_Machine.elevatorMotor_TractionSheave.diameter}} | {{elevatorMotor_Machine.elevatorMotor_TractionSheave.grooveDistance}}</span> mm</div>
                                                        <div class="my_table_parameters_infoText">{{$t('1301')}} | {{$t('1294')}}</div>
                                                    </div>
                                                    <div v-if="elevatorMotor_Machine.elevatorMotor_Machine.design == 'gearless'" class="my_div_minWidth_100" style="margin-left:25px">
                                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{elevatorMotor_Machine.elevatorMotor_Machine.maximumAxleLoad}}</span> kg</div>
                                                        <div class="my_table_parameters_infoText">{{$t('3048')}}</div>
                                                    </div>
                                                    <div v-if="elevatorMotor_Machine.elevatorMotor_Machine.design != 'gearless'" class="my_div_minWidth_100" style="margin-left:25px">
                                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{elevatorMotor_Machine.elevatorMotor_Machine.gearRatio1}} : {{elevatorMotor_Machine.elevatorMotor_Machine.gearRatio2}}</span></div>
                                                        <div class="my_table_parameters_infoText">{{$t('410')}}</div>
                                                    </div>

                                                    <div v-if="elevatorMotor_Machine.elevatorMotor_Machine.design == 'gearless'" class="my_div_minWidth_100 my_div_maginleft_5">
                                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{elevatorMotor_Machine.elevatorMotor_Machine.nominalRotationSpeed}}</span> rpm</div>
                                                        <div class="my_table_parameters_infoText">{{$t('1091')}}</div>
                                                    </div>
                                                    <div v-if="elevatorMotor_Machine.elevatorMotor_Machine.design != 'gearless'" class="my_div_minWidth_100 my_div_maginleft_5">
                                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{elevatorMotor_Machine.elevatorMotor_Machine.operatingRotationSpeed}}</span> rpm</div>
                                                        <div class="my_table_parameters_infoText">{{$t('3272')}}</div>
                                                    </div>
                                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{ValueDecimalSeparator(elevatorMotor_Machine.elevatorMotor_Machine.nominalCurrent)}}</span> A</div>
                                                        <div class="my_table_parameters_infoText">{{$t('1381')}}</div>
                                                    </div>
                                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{elevatorMotor_Machine.elevatorMotor_Machine.nominalTorque}}</span> Nm</div>
                                                        <div class="my_table_parameters_infoText">{{$t('3204')}}</div>
                                                    </div>
                                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{ValueDecimalSeparator(elevatorMotor_Machine.elevatorMotor_Machine.mechanicalUtilization)}}</span> %</div>
                                                        <div class="my_table_parameters_infoText">{{$t('3052')}}</div>
                                                    </div>
                                                    <!--<div v-if="elevatorMotor_Machine.elevatorMotor_Machine.design == 'gearless'" class="width1500 my_div_minWidth_100"></div>-->
                                                    <!--<div v-if="elevatorMotor_Machine.elevatorMotor_Machine.design != 'gearless'" class="my_div_minWidth_100">
                                                        <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine.elevatorMotor_Machine.gearRatio1}} : {{elevatorMotor_Machine.elevatorMotor_Machine.gearRatio2}}</span></div>
                                                        <div class="my_table_parameters_infoText">{{$t('410')}}</div>
                                                    </div>
                                                    <div class="my_div_minWidth_100">
                                                        <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine.elevatorMotor_Machine.axleLoad}}</span> kg</div>
                                                        <div class="my_table_parameters_infoText">{{$t('1326')}}</div>
                                                    </div>
                                                    <div class="my_div_minWidth_100">
                                                        <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine.elevatorMotor_Machine.operatingRotationSpeed}}</span> rpm</div>
                                                        <div class="my_table_parameters_infoText">{{$t('3272')}}</div>
                                                    </div>
                                                    <div class="my_div_minWidth_100">
                                                        <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(elevatorMotor_Machine.elevatorMotor_Machine.operatingCurrent)}}</span> A</div>
                                                        <div class="my_table_parameters_infoText">{{$t('3049')}}</div>
                                                    </div>
                                                    <div class="my_div_minWidth_100">
                                                        <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine.elevatorMotor_Machine.operatingTorque}}</span> Nm</div>
                                                        <div class="my_table_parameters_infoText">{{$t('3051')}}</div>
                                                    </div>
                                                    <div class="my_div_minWidth_100">
                                                        <div class="my_fontSize_1Point4">
                                                            <span class="my_font-weight_600">{{ValueDecimalSeparator(elevatorMotor_Machine.elevatorMotor_Machine.mechanicalUtilization)}}</span> %
                                                        </div>
                                                        <div class="my_table_parameters_infoText">{{$t('3052')}}</div>
                                                    </div>-->
                                                </div>
                                            </div>
                                            <div style="background-color:#f5f5f5; border: 1px solid #ced2d8;display:grid; margin-left: 10px">
                                                <div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center; padding: 8px; height: 100%">
                                                    <div>
                                                        <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{$t('3053')}}</div>
                                                    </div>
                                                    <div style="text-align:center; margin-top:5px; margin-bottom:5px">
                                                        <!--<div v-if="elevatorMotor_Machine.tractionCheck_Executed == false">
                                                            <div class="ZAfont_family ZAfont_size">{{$t('3054')}}</div>
                                                        </div>-->
                                                        <div v-if="elevatorMotor_Machine.tractionCheck_Executed == true">
                                                            <div v-if="elevatorMotor_Machine.tractionCheck_Passed == true">
                                                                <!--<div class="ZAfont_family" style="color:#228B22;"><span class="far fa-check fa-1x"></span></div>-->
                                                                <div class="my_fontSize_1 ZAfont_family" style="color:#228B22">{{$t('3055')}}</div>
                                                                <span class="my_fontSize_1 ZAfont_family" v-if="((elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1131')}}: {{elevatorMotor_Machine.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                                <span class="my_fontSize_1 ZAfont_family" v-if="((elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='V-groove undercut')&&(elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1133')}}: {{elevatorMotor_Machine.elevatorMotor_TractionSheave.vgrooveAngle}} ° / {{elevatorMotor_Machine.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                                <span class="my_fontSize_1 ZAfont_family" v-if="((elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='belt')&&(elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1360')}}: {{ValueDecimalSeparator(elevatorMotor_Machine.suspensionMeans.cordDiameter)}} x {{ValueDecimalSeparator(elevatorMotor_Machine.suspensionMeans.beltWidth)}}</span>
                                                                <span class="my_fontSize_1 ZAfont_family" v-if="((elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='circular groove')&&(elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1424')}}</span>
                                                                <span class="my_fontSize_1 ZAfont_family" v-if="((elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='V-groove')&&(elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1423')}} {{$t('1319')}}: {{elevatorMotor_Machine.elevatorMotor_TractionSheave.vgrooveAngle}} °</span>
                                                                <span class="my_fontSize_1 ZAfont_family" v-if="((elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1131')}} {{$t('1319')}}: {{elevatorMotor_Machine.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                                <span class="my_fontSize_1 ZAfont_family" v-if="((elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='V-groove undercut')&&(elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1441')}}: {{elevatorMotor_Machine.elevatorMotor_TractionSheave.vgrooveAngle}}° / {{elevatorMotor_Machine.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                            </div>
                                                            <div v-if="elevatorMotor_Machine.tractionCheck_Passed == false">
                                                                <!--<div class="ZAfont_family" style="color:red;"><span class="far fa-times fa-3x"></span></div>-->
                                                                <div class="my_fontSize_1 ZAfont_family" style="color:red">{{$t('3056')}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="width:100%; text-align:center;">
                                                        <div v-if="elevatorMotor_Machine.tractionCheck_Executed == false">
                                                            <button @click="checkIf_wrappingAngle_hasToBeCalculated_before_tractionCheck(elevatorMotor_Machine)" type="button" class="btn my_btn_standard" style="width:100%;">{{$t('3057')}}</button>
                                                        </div>
                                                        <div v-if="elevatorMotor_Machine.tractionCheck_Executed == true">
                                                            <div><a @click="checkIf_wrappingAngle_hasToBeCalculated_before_tractionCheck_with_Data_fromItemOf_elevatorMotor_Machine_Selection(elevatorMotor_Machine)" class="ZAfont_family ZAfont_size" href="#">{{$t('3098')}}</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div style="margin-top:15px">
                                    <div v-if="elevatorMotor_Machine_Selection_Filtered==false">
                                        <button v-if="elevatorMotor_Machine_Selection_lastIndex < this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.length" @click="show_more_elevatorMotor_Machines_fromSelection" type="button" class="btn my_btn_standard_blue" style="margin-right:15px">{{$t('3059')}}</button>
                                        <button v-if="elevatorMotor_Machine_Selection_lastIndex < this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.length" @click="show_full_elevatorMotor_Machine_Selection" type="button" class="btn my_btn_standard" style="margin-right:15px">{{$t('3060')}}</button>
                                        <button v-if="elevatorMotor_Machine_Selection_lastIndex > 5" @click="show_less_elevatorMotor_Machines_fromSelection" type="button" class="btn my_btn_standard">{{$t('3061')}}</button>
                                    </div>
                                    <div v-if="elevatorMotor_Machine_Selection_Filtered==true">
                                        <button v-if="elevatorMotor_Machine_Selection_lastIndex < filtered_elevatorMotor_Machine_Selection.length" @click="show_more_elevatorMotor_Machines_fromSelection" type="button" class="btn my_btn_standard_blue" style="margin-right:15px">{{$t('3059')}}</button>
                                        <button v-if="elevatorMotor_Machine_Selection_lastIndex < filtered_elevatorMotor_Machine_Selection.length" @click="show_full_elevatorMotor_Machine_Selection" type="button" class="btn my_btn_standard" style="margin-right:15px">{{$t('3060')}}</button>
                                        <button v-if="elevatorMotor_Machine_Selection_lastIndex > 5" @click="show_less_elevatorMotor_Machines_fromSelection" type="button" class="btn my_btn_standard">{{$t('3061')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="elevatorMotor_Machine_Selection.length==0">
                                <div v-if="elevatorMotor_Machine_Selection_Filtered == true">
                                    <div style="text-align:center; margin-top: 60px">
                                        <span style="font-size:5rem; color:#00338e"><span class="fal fa-info-square"></span></span>
                                        <div class="ZAfont_family ZAfont_size">{{$t('3063')}}</div>
                                        <div class="ZAfont_family ZAfont_size" style="margin-top:20px" v-html="$t('3064', {total: elevatorMotor_Machine_Selection_fullLength})"></div>
                                        <div class="ZAfont_family ZAfont_size">{{$t('3065')}}</div>
                                        <button @click="remove_allElementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria()" class="btn my_btn_standard_blue" style="margin-top:20px">{{$t('3066')}}</button>
                                    </div>
                                </div>
                                <div v-if="elevatorMotor_Machine_Selection_Filtered == false">
                                    <div style="text-align:center; margin-top: 60px">
                                        <span style="font-size:5rem; color:#00338e"><span class="fal fa-info-square"></span></span>
                                        <div class="ZAfont_family ZAfont_size">{{$t('3206')}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="((elevatorMotor_Machine_Selection_Shown==false)&&(elevatorMotor_Machine_Selected==true))">
                            <ul style="list-style:none; margin:0; padding:0">
                                <li style="display:block; border: 2px solid blue;">
                                    <div class="my_div_width1500" style="display:grid; grid-template-columns: auto 280px; grid-template-rows:100%">
                                        <div style="border: 1px solid #ced2d8; display:grid;">
                                            <div style="display:grid; grid-template-columns:auto auto auto auto auto auto auto; grid-template-rows:auto auto auto;  padding:9px; overflow: auto">
                                                <div style="align-self:center; grid-column: 1 / span 7">
                                                    <div class="my_fontWeight_600 my_fontSize_1Point1 ZAfont_family" :style='{color:customizedColor(selected_elevatorMotor_Machine.customized)}'>
                                                        <span>{{selected_elevatorMotor_Machine.elevatorMotor_Machine.typeDesignation}}</span>
                                                        <span v-if="selected_elevatorMotor_Machine.customized===true"> ({{selected_elevatorMotor_Machine.customerName}})</span>
                                                    </div>
                                                </div>
                                                <div class="width1500" style="grid-column: 1 / span 1; grid-row: 2 / span 2">
                                                    <Images :imageFilePath="selected_elevatorMotor_Machine.imagePath" :maxImageHeight="90" :maxImageWidth="150"></Images>
                                                </div>
                                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.diameter}} | {{selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.grooveDistance}}</span> mm</div>
                                                    <div class="my_table_parameters_infoText">{{$t('1301')}} | {{$t('1294')}}</div>
                                                </div>
                                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{selected_elevatorMotor_Machine.elevatorMotor_Machine.maximumAxleLoad}}</span> kg</div>
                                                    <div class="my_table_parameters_infoText">{{$t('3048')}}</div>
                                                </div>
                                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{selected_elevatorMotor_Machine.elevatorMotor_Machine.nominalRotationSpeed}}</span> rpm</div>
                                                    <div class="my_table_parameters_infoText">{{$t('1091')}}</div>
                                                </div>
                                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(selected_elevatorMotor_Machine.elevatorMotor_Machine.nominalCurrent)}}</span> A</div>
                                                    <div class="my_table_parameters_infoText">{{$t('1381')}}</div>
                                                </div>
                                                <div class="my_div_minWidth_100">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{selected_elevatorMotor_Machine.elevatorMotor_Machine.nominalTorque}}</span> Nm</div>
                                                    <div class="my_table_parameters_infoText">{{$t('3204')}}</div>
                                                </div>
                                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(selected_elevatorMotor_Machine.elevatorMotor_Machine.electricalUtilization)}}</span> %</div>
                                                    <div class="my_table_parameters_infoText">{{$t('3050')}}</div>
                                                </div>
                                                <div v-if="selected_elevatorMotor_Machine.elevatorMotor_Machine.design == 'gearless'" class="width1500 my_div_minWidth_100"></div>
                                                <div v-if="selected_elevatorMotor_Machine.elevatorMotor_Machine.design != 'gearless'" class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{selected_elevatorMotor_Machine.elevatorMotor_Machine.gearRatio1}} : {{selected_elevatorMotor_Machine.elevatorMotor_Machine.gearRatio2}}</span></div>
                                                    <div class="my_table_parameters_infoText">{{$t('410')}}</div>
                                                </div>
                                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{selected_elevatorMotor_Machine.elevatorMotor_Machine.axleLoad}}</span> kg</div>
                                                    <div class="my_table_parameters_infoText">{{$t('1326')}}</div>
                                                </div>

                                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{selected_elevatorMotor_Machine.elevatorMotor_Machine.operatingRotationSpeed}}</span> rpm</div>
                                                    <div class="my_table_parameters_infoText">{{$t('3272')}}</div>
                                                </div>
                                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(selected_elevatorMotor_Machine.elevatorMotor_Machine.operatingCurrent)}}</span> A</div>
                                                    <div class="my_table_parameters_infoText">{{$t('3049')}}</div>
                                                </div>
                                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{selected_elevatorMotor_Machine.elevatorMotor_Machine.operatingTorque}}</span> Nm</div>
                                                    <div class="my_table_parameters_infoText">{{$t('3051')}}</div>
                                                </div>
                                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                    <div class="my_fontSize_1Point4">
                                                        <span class="my_font-weight_600">{{ValueDecimalSeparator(selected_elevatorMotor_Machine.elevatorMotor_Machine.mechanicalUtilization)}}</span> %
                                                        <!--<span class="hover-text">
                                                            <span>i</span>
                                                            <span class="tooltip-text" id="tooltip_top">{{$t('3377')}}</span>
                                                        </span>-->
                                                    </div>
                                                    <div class="my_table_parameters_infoText">{{$t('3052')}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="background-color:#f5f5f5; border: 1px solid #ced2d8;display:grid; margin-left: 10px">
                                            <div style="display:flex; flex-direction:column; justify-content:space-between; align-items:center; padding:8px; height:100%">
                                                <div>
                                                    <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{$t('3053')}}</div>
                                                </div>
                                                <div style="text-align:center; margin-top:10px; margin-bottom:10px">
                                                    <div class="ZAfont_family" style="color:#228B22;"><span class="far fa-check fa-3x"></span></div>
                                                    <div class="my_fontSize_1Point1 ZAfont_family" style="color:#228B22">{{$t('3055')}}</div>
                                                    <span class="my_fontSize_1 ZAfont_family" v-if="((selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1131')}}: {{selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                    <span class="my_fontSize_1 ZAfont_family" v-if="((selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='V-groove undercut')&&(selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1133')}}: {{selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.vgrooveAngle}} ° / {{selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                    <span class="my_fontSize_1 ZAfont_family" v-if="((selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='belt')&&(selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1360')}}: {{ValueDecimalSeparator(selected_elevatorMotor_Machine.suspensionMeans.cordDiameter)}} x {{ValueDecimalSeparator(selected_elevatorMotor_Machine.suspensionMeans.beltWidth)}}</span>
                                                    <span class="my_fontSize_1 ZAfont_family" v-if="((selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='circular groove')&&(selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1424')}}</span>
                                                    <span class="my_fontSize_1 ZAfont_family" v-if="((selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='V-groove')&&(selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1423')}} {{$t('1319')}}: {{selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.vgrooveAngle}} °</span>
                                                    <span class="my_fontSize_1 ZAfont_family" v-if="((selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1131')}} {{$t('1319')}}: {{selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                    <span class="my_fontSize_1 ZAfont_family" v-if="((selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile=='V-groove undercut')&&(selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1441')}}: {{selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.vgrooveAngle}}° / {{selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                </div>
                                                <div style="width:100%; text-align:center;">
                                                    <div><a @click="get_tractionCheck_Data_fromItemOf_elevatorMotor_Machine_Selection(selected_elevatorMotor_Machine)" class="ZAfont_family ZAfont_size" href="#">{{$t('3058')}}</a></div>
                                                    <div v-if="selected_elevatorMotor_Machine.suspensionMeans.wrappingAngleCalculated==true">
                                                        <hr style="margin-top:0.4rem; margin-bottom:0.4rem" />
                                                        <div><a @click="get_wrappingAngleCalculation_Object_fromItemOf_elevatorMotor_Machine_Selection(selected_elevatorMotor_Machine)" class="ZAfont_family ZAfont_size" href="#">{{$t('3238')}}</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="((elevatorMotor_Machine_Selection_Shown==false)&&(loaderModal_Shown==false))">
            <div v-if="configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
                <div style="grid-column:2 / span 1">
                    <div v-if="elevatorMotor_Machine_TractionSheaveSide_Selection.length == 1">
                        <div class="my_fontSize_1Point2 ZAfont_family">{{$t('268')}}: <span class="my_font-weight_600">{{$t(elevatorMotor_Machine_TractionSheaveSide_Selection[0].elevatorMotor_Machine_TractionSheaveSide.textKey)}}</span></div>
                    </div>
                    <div v-if="elevatorMotor_Machine_TractionSheaveSide_Selection.length > 1">
                        <div class="my_fontSize_1Point2 ZAfont_family">{{$t('268')}}</div>
                        <ul class="my_elevatorMotor_TemperatureMonitoring my_fontSize_1Point1 my_fontWeight_600">
                            <li style="cursor: pointer" v-for="elevatorMotor_Machine_TractionSheaveSide in elevatorMotor_Machine_TractionSheaveSide_Selection" @click="change_Selected_at_elevatorMotor_Machine_TractionSheaveSide_Selection(elevatorMotor_Machine_TractionSheaveSide)" v-bind:class="{active: elevatorMotor_Machine_TractionSheaveSide.selected == true}">{{$t(elevatorMotor_Machine_TractionSheaveSide.elevatorMotor_Machine_TractionSheaveSide.textKey)}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:5px">
                <div style="grid-column:2 / span 1">
                    <div v-if="elevatorMotor_Machine_InstallationPosition_Selection.length == 1">
                        <div class="my_fontSize_1Point2 ZAfont_family">{{$t('269')}}: <span class="my_font-weight_600">{{$t(elevatorMotor_Machine_InstallationPosition_Selection[0].elevatorMotor_Machine_InstallationPosition.textKey)}}</span></div>
                    </div>
                    <div v-if="elevatorMotor_Machine_InstallationPosition_Selection.length > 1">
                        <div class="my_fontSize_1Point2 ZAfont_family">{{$t('269')}}</div>
                        <ul class="my_elevatorMotor_TemperatureMonitoring my_fontSize_1Point1 my_fontWeight_600">
                            <li style="cursor: pointer" v-for="elevatorMotor_Machine_InstallationPosition in elevatorMotor_Machine_InstallationPosition_Selection" @click="change_Selected_at_elevatorMotor_Machine_InstallationPosition_Selection(elevatorMotor_Machine_InstallationPosition)" v-bind:class="{active: elevatorMotor_Machine_InstallationPosition.selected == true}">{{$t(elevatorMotor_Machine_InstallationPosition.elevatorMotor_Machine_InstallationPosition.textKey)}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="elevatorMotor_TemperatureMonitoring_Selection.length>0" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
                <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
                <div>
                    <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                        <span>{{$t('3067')}}</span>
                        <span v-if="((elevatorMotor_TemperatureMonitoring_Selection.length==1)||((userPreference_affected_selected_elevatorMotor_TemperatureMonitoring==true)&&(elevatorMotor_TemperatureMonitoring_Selection_Shown==false)))">: <span> {{selected_elevatorMotor_TemperatureMonitoring.elevatorMotor_TemperatureMonitoring.typeDesignation}} </span></span>
                        <a v-if="((elevatorMotor_TemperatureMonitoring_Selection.length>1)&&(elevatorMotor_TemperatureMonitoring_Selection_Shown==false))" @click="elevatorMotor_TemperatureMonitoring_Selection_Shown=true" class="btn my_btn_changeSelection">{{$t('3182')}}</a>
                    </div>
                </div>
                <div style="grid-column:2 / span 1">
                    <div v-if="((elevatorMotor_TemperatureMonitoring_Selection.length>1)&&(elevatorMotor_TemperatureMonitoring_Selection_Shown==true))">
                        <ul class="my_elevatorMotor_TemperatureMonitoring my_fontSize_1Point1 my_fontWeight_600">
                            <li style="cursor: pointer" v-for="elevatorMotor_TemperatureMonitoring in elevatorMotor_TemperatureMonitoring_Selection" @click="change_Selected_at_elevatorMotor_TemperatureMonitoring_Selection(elevatorMotor_TemperatureMonitoring)" v-bind:class="{active: elevatorMotor_TemperatureMonitoring.selected == true}">{{elevatorMotor_TemperatureMonitoring.elevatorMotor_TemperatureMonitoring.typeDesignation}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="configurationSteps.elevatorMotor_ForcedVentilation.available == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
                <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
                <div>
                    <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                        <span>{{$t('298')}}</span>
                        <span v-if="((elevatorMotor_ForcedVentilation.necessary == true)||((userPreference_affected_elevatorMotor_ForcedVentilation_Selected==true)&&(elevatorMotor_ForcedVentilation_Options_Shown==false)))">: <span> {{$t('1035')}} </span></span>
                        <a v-if="((elevatorMotor_ForcedVentilation_Options_Shown==false)&&(elevatorMotor_ForcedVentilation.necessary==false))" @click="elevatorMotor_ForcedVentilation_Options_Shown=true" class="btn my_btn_changeSelection">{{$t('3182')}}</a>
                    </div>
                </div>
                <div style="grid-column:2 / span 1">
                    <div v-if="((elevatorMotor_ForcedVentilation_Options_Shown==true)&&(elevatorMotor_ForcedVentilation.necessary==false))">
                        <ul class="my_elevatorMotor_TemperatureMonitoring my_fontSize_1Point1 my_fontWeight_600">
                            <li style="cursor: pointer" @click="change_Selected_at_elevatorMotor_ForcedVentilation(false)" v-bind:class="{active: elevatorMotor_ForcedVentilation.selected == false}">{{$t('1036')}}</li>
                            <li style="cursor: pointer" @click="change_Selected_at_elevatorMotor_ForcedVentilation(true)" v-bind:class="{active: elevatorMotor_ForcedVentilation.selected == true}">{{$t('1035')}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="configurationSteps.elevatorMotor_Brake.available==true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
                <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
                <div>
                    <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                        <span>{{$t('3260')}}</span>
                        <span v-if="(((elevatorMotor_Brake_Selection.length==1)||(userPreferences_affected_selected_elevatorMotor_Brake==true)&&(elevatorMotor_Brake_Selections_Shown==false)))">: <span> {{selected_elevatorMotor_Brake_Voltage.nominalVoltage}} V / {{$t(selected_elevatorMotor_Brake_MechanicalRelease.textKey)}} / {{$t(selected_elevatorMotor_Brake_ReleaseMonitoring.textKey)}} </span></span>
                        <a v-if="((elevatorMotor_Brake_Selection.length>1)&&(elevatorMotor_Brake_Selections_Shown==false))" @click="elevatorMotor_Brake_Selections_Shown=true" class="btn my_btn_changeSelection">{{$t('3182')}}</a>
                    </div>
                </div>
                <div style="grid-column:2 / span 1">
                    <div v-if="(elevatorMotor_Brake_Selection.length>1)&&(elevatorMotor_Brake_Selections_Shown==true)">
                        <div>
                            <div class="my_fontSize_1Point2 ZAfont_family">
                                <span>{{$t('3073')}}</span>
                                <span v-if="elevatorMotor_Brake_Voltage_Selection.length==1">: <span v-if="selected_elevatorMotor_Brake_Voltage.nominalVoltage == selected_elevatorMotor_Brake_Voltage.switchOnVoltage" class="my_font-weight_600"> {{selected_elevatorMotor_Brake_Voltage.nominalVoltage}}</span><span v-if="selected_elevatorMotor_Brake_Voltage.nominalVoltage != selected_elevatorMotor_Brake_Voltage.switchOnVoltage" class="my_font-weight_600">{{selected_elevatorMotor_Brake_Voltage.switchOnVoltage}} / {{selected_elevatorMotor_Brake_Voltage.nominalVoltage}}</span> V</span>
                            </div>
                            <div v-if="elevatorMotor_Brake_Voltage_Selection.length>1">
                                <ul class="my_elevatorMotor_Brake_Voltage_Selection my_fontSize_1Point1">
                                    <li style="cursor: pointer" v-for="elevatorMotor_Brake_Voltage in elevatorMotor_Brake_Voltage_Selection" @click="change_Selected_at_elevatorMotor_Brake_Voltage_Selection(elevatorMotor_Brake_Voltage)" v-bind:class="{active: elevatorMotor_Brake_Voltage.selected}"><span v-if="elevatorMotor_Brake_Voltage.nominalVoltage == elevatorMotor_Brake_Voltage.switchOnVoltage" class="my_fontWeight_600">{{elevatorMotor_Brake_Voltage.nominalVoltage}}</span><span v-if="elevatorMotor_Brake_Voltage.nominalVoltage != elevatorMotor_Brake_Voltage.switchOnVoltage" class="my_fontWeight_600">{{elevatorMotor_Brake_Voltage.switchOnVoltage}} / {{elevatorMotor_Brake_Voltage.nominalVoltage}}</span> V</li>
                                </ul>
                            </div>
                        </div>
                        <div style="margin-top:10px">
                            <div class="my_fontSize_1Point2 ZAfont_family">
                                <span>{{$t('3045')}}</span>
                                <span v-if="elevatorMotor_Brake_MechanicalRelease_Selection.length==1">: <span class="my_font-weight_600"> {{$t(selected_elevatorMotor_Brake_MechanicalRelease.textKey)}}</span></span>
                            </div>
                            <div v-if="elevatorMotor_Brake_MechanicalRelease_Selection.length>1">
                                <ul class="my_elevatorMotor_Brake_MechanicalRelease_Selection my_fontSize_1Point1 my_fontWeight_600">
                                    <li style="cursor: pointer" v-for="elevatorMotor_Brake_MechanicalRelease in elevatorMotor_Brake_MechanicalRelease_Selection" @click="change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection(elevatorMotor_Brake_MechanicalRelease)" v-bind:class="{active: elevatorMotor_Brake_MechanicalRelease.selected, disabled: elevatorMotor_Brake_MechanicalRelease.disabled}">{{$t(elevatorMotor_Brake_MechanicalRelease.textKey)}}</li>
                                </ul>
                            </div>
                        </div>
                        <div style="margin-top:10px; margin-bottom:10px">
                            <div class="my_fontSize_1Point2 ZAfont_family">
                                <span>{{$t('208')}}</span>
                                <span v-if="elevatorMotor_Brake_ReleaseMonitoring_Selection.length==1">: <span class="my_font-weight_600"> {{$t(selected_elevatorMotor_Brake_ReleaseMonitoring.textKey)}}</span></span>
                            </div>
                            <div v-if="elevatorMotor_Brake_ReleaseMonitoring_Selection.length>1">
                                <ul class="my_elevatorMotor_Brake_ReleaseMonitoring_Selection my_fontSize_1Point1 my_fontWeight_600">
                                    <li style="cursor: pointer" v-for="elevatorMotor_Brake_ReleaseMonitoring in elevatorMotor_Brake_ReleaseMonitoring_Selection" @click="change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection(elevatorMotor_Brake_ReleaseMonitoring)" v-bind:class="{active: elevatorMotor_Brake_ReleaseMonitoring.selected, disabled: elevatorMotor_Brake_ReleaseMonitoring.disabled}">{{$t(elevatorMotor_Brake_ReleaseMonitoring.textKey)}}</li>
                                </ul>
                            </div>
                        </div>
                        <div style="margin-bottom:10px" v-if="elevatorMotor_Brake_Connection_Selection.length>0">
                            <div class="my_fontSize_1Point2 ZAfont_family">
                                <span>{{$t('3441')}}</span>
                                <span v-if="elevatorMotor_Brake_Connection_Selection.length==1">: <span class="my_font-weight_600" v-if="selected_elevatorMotor_Brake_Connection.useTextKey == true"> {{$t(selected_elevatorMotor_Brake_Connection.textKey)}}</span><span class="my_font-weight_600" v-if="selected_elevatorMotor_Brake_Connection.useTextKey == false"> {{selected_elevatorMotor_Brake_Connection.text}}</span></span>
                            </div>
                            <div v-if="elevatorMotor_Brake_Connection_Selection.length>1">
                                <ul class="my_elevatorMotor_Brake_Connection_Selection my_fontSize_1Point1">
                                    <li style="cursor: pointer" v-for="elevatorMotor_Brake_Connection in elevatorMotor_Brake_Connection_Selection" @click="change_Selected_at_elevatorMotor_Brake_Connection_Selection(elevatorMotor_Brake_Connection)" v-bind:class="{active: elevatorMotor_Brake_Connection.selected}"><span class="my_fontWeight_600" v-if="elevatorMotor_Brake_Connection.useTextKey == true">{{$t(elevatorMotor_Brake_Connection.textKey)}}</span><span class="my_fontWeight_600" v-if="elevatorMotor_Brake_Connection.useTextKey == false">{{elevatorMotor_Brake_Connection.text}}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div style="margin-bottom:10px" v-if="elevatorMotor_Brake_DeliverBowdenwire_Selection.length>0">
                            <div class="my_fontSize_1Point2 ZAfont_family">
                                <span>{{$t('3442')}}</span>
                                <span v-if="elevatorMotor_Brake_DeliverBowdenwire_Selection.length==1">: <span class="my_font-weight_600" v-if="selected_elevatorMotor_Brake_DeliverBowdenwire.deliver.useTextKey == true"> {{$t(selected_elevatorMotor_Brake_DeliverBowdenwire.deliver.textKey)}}</span><span class="my_font-weight_600" v-if="selected_elevatorMotor_Brake_DeliverBowdenwire.deliver.useTextKey == false"> {{selected_elevatorMotor_Brake_DeliverBowdenwire.deliver.text}}</span></span>
                            </div>
                            <div v-if="elevatorMotor_Brake_DeliverBowdenwire_Selection.length>1">
                                <ul class="my_elevatorMotor_Brake_Bowdenwire_Selection my_fontSize_1Point1">
                                    <li style="cursor: pointer" v-for="elevatorMotor_Brake_DeliverBowdenwire in elevatorMotor_Brake_DeliverBowdenwire_Selection" @click="change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection(elevatorMotor_Brake_DeliverBowdenwire)" v-bind:class="{active: elevatorMotor_Brake_DeliverBowdenwire.selected}"><span class="my_fontWeight_600" v-if="elevatorMotor_Brake_DeliverBowdenwire.deliver.useTextKey == true">{{$t(elevatorMotor_Brake_DeliverBowdenwire.deliver.textKey)}}</span><span class="my_fontWeight_600" v-if="elevatorMotor_Brake_DeliverBowdenwire.deliver.useTextKey == false">{{elevatorMotor_Brake_DeliverBowdenwire.deliver.text}}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div style="margin-bottom:10px" v-if="elevatorMotor_Brake_LengthBowdenwire_Selection.length>0">
                            <div class="my_fontSize_1Point2 ZAfont_family">
                                <span>{{$t('3443')}}</span>
                                <span v-if="elevatorMotor_Brake_LengthBowdenwire_Selection.length==1">: <span class="my_font-weight_600"> {{selected_elevatorMotor_Brake_LengthBowdenwire.length}}</span></span>
                            </div>
                            <div v-if="elevatorMotor_Brake_LengthBowdenwire_Selection.length>1">
                                <ul class="my_elevatorMotor_Brake_Bowdenwire_Selection my_fontSize_1Point1">
                                    <li style="cursor: pointer" v-for="elevatorMotor_Brake_LengthBowdenwire in elevatorMotor_Brake_LengthBowdenwire_Selection" @click="change_Selected_at_elevatorMotor_Brake_LengthBowdenwire_Selection(elevatorMotor_Brake_LengthBowdenwire)" v-bind:class="{active: elevatorMotor_Brake_LengthBowdenwire.selected}"><span class="my_fontWeight_600">{{elevatorMotor_Brake_LengthBowdenwire.length}}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-if="elevatorMotor_Brake_Selected==true" class="ZAfont_family ZAfont_size" style="background-color:#f5f5f5; padding:0.75rem">
                        <div class="my_color_00338e my_fontWeight_600">{{$t('3177')}}</div>
                        <div v-if="selected_elevatorMotor_Brake.elevatorMotor_Brake.overexcited == true">{{$t('1431')}}</div>
                        <div>{{$t('3210')}}: <span class="my_font-weight_700">{{selected_elevatorMotor_Brake.elevatorMotor_Brake.typeDesignation}}</span></div>
                        <div>{{$t('3211')}}: <span class="my_font-weight_700">{{ValueDecimalSeparator(selected_elevatorMotor_Brake.elevatorMotor_Brake.brakingDeceleration)}}</span> m/s²</div>
                        <div>{{$t('1313')}}: <span class="my_font-weight_700">{{ValueDecimalSeparator(selected_elevatorMotor_Brake.elevatorMotor_Brake.brakingDistance)}}</span> m</div>
                        <div>{{$t('3212')}}: <span class="my_font-weight_700">{{ValueDecimalSeparator(selected_elevatorMotor_Brake.elevatorMotor_Brake.brakeEnergy)}}</span> J</div>
                        <div>{{$t('3213')}}: <span class="my_font-weight_700">{{selected_elevatorMotor_Brake.elevatorMotor_Brake.brakingPower}}</span> W</div>
                        <div>{{$t('3214')}}: <span class="my_font-weight_700">{{selected_elevatorMotor_Brake.elevatorMotor_Brake.nominalBrakeTorque}}</span> Nm</div>
                        <div>t10: <span class="my_font-weight_700"> {{selected_elevatorMotor_Brake.elevatorMotor_Brake.maximumResponseTimeT1010percentOfNominalBrakeTorque}}</span> ms</div>
                        <div>t50: <span class="my_font-weight_700"> {{selected_elevatorMotor_Brake.elevatorMotor_Brake.maximumResponseTimeT5050percentOfNominalBrakeTorque}}</span> ms</div>
                        <div>t90: <span class="my_font-weight_700"> {{selected_elevatorMotor_Brake.elevatorMotor_Brake.maximumResponseTimeT9090percentOfNominalBrakeTorque}}</span> ms</div>
                    </div>
                </div>
            </div>
            <div v-if="elevatorMotor_Encoder_Selection.length>0" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
                <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
                <div>
                    <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                        <span>{{$t('3075')}}</span>
                        <span v-if="((elevatorMotor_Encoder_Selection.length==1)||((userPreference_affected_selected_elevatorMotor_Encoder==true)&&(elevatorMotor_Encoder_Selection_Shown==false)))">: <span> {{selected_elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation}} </span></span>
                        <a v-if="((elevatorMotor_Encoder_Selection.length>1)&&(elevatorMotor_Encoder_Selection_Shown==false))" @click="elevatorMotor_Encoder_Selection_Shown=true" class="btn my_btn_changeSelection">{{$t('3182')}}</a>
                    </div>
                </div>
                <div v-if="((elevatorMotor_Encoder_Selection.length>1)&&(elevatorMotor_Encoder_Selection_Shown==true))" style="grid-column:2 / span 1">
                    <ul class="my_elevatorMotor_Encoder my_fontSize_1Point1 my_fontWeight_600">
                        <li style="cursor: pointer" v-for="elevatorMotor_Encoder in elevatorMotor_Encoder_Selection" @click="change_Selected_at_elevatorMotor_Encoder_Selection(elevatorMotor_Encoder)" v-bind:class="{active: elevatorMotor_Encoder.selected}">{{elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation}}</li>
                    </ul>
                </div>
            </div>
            <div v-if="driveTechnologies_Passed==true" class="my_btn_nextConfigurationStep_position">
                <button @click="furtherToControlTechnologies()" class="btn my_btn_nextConfigurationStep">{{$t('1023')}}</button>
            </div>
        </div>
        <div class="my_modal" v-bind:class="{show: tractionCheck_Modal_Shown==true}" align="center">
            <div class="my_modal-dialog size-lg">
                <div class="my_modal-content">
                    <div class="my_modal-header">
                        <div style="display:flex; align-items:center">
                            <div v-if="fromInputs_wrappingAngle_Calculated==true" @click="backTo_wrappingAngle_Modal" style="margin-right:12px">
                                <span class="fas fa-angle-left fa-2x"></span>
                            </div>
                            <div class="my_fontSize_1Point5 my_fontWeight_600 my_color_00338e ZAfont_family" style="align-self:center">{{$t('3053')}}</div>
                        </div>
                        <div>
                            <span @click="tractionCheck_Modal_Shown=false" class="fal fa-times fa-2x"></span>
                        </div>
                    </div>
                    <div style="height: 35px;">
                        <div v-if="loader_forLoadingAllContent_at_tractionCheck_Modal_Shown == false">
                            <div v-if="((Object.keys(this.tractionCheck).length > 0)&&(loader_forLoadingResults_at_tractionCheck_Modal_Shown==false))" style="height:100%; color:#ffffff;">
                                <div v-if="tractionCheck.tractionCheckPassedInTotal==true" style="display:flex; align-items: center; justify-content:center; height:100%; background-color: #228B22;">
                                    <div class="ZAfont_family my_fontSize_1Point2 my_font-weight_500" style="text-transform:uppercase; letter-spacing:0.15rem">{{$t('3055')}}</div>
                                </div>
                                <div v-if="tractionCheck.tractionCheckPassedInTotal==false" style="display:flex; align-items:center; justify-content:center; height:100%; background-color: red;">
                                    <div class="ZAfont_family my_fontSize_1Point2 my_font-weight_500" style="text-transform:uppercase; letter-spacing:0.15rem">{{$t('3056')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my_modal-body" style="height: calc(100vh - 180px)">
                        <div v-if="loader_forLoadingAllContent_at_tractionCheck_Modal_Shown == true">
                            <div style="display:flex; align-items:center; justify-content:center">
                                <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                            </div>
                        </div>
                        <div v-else>
                            <div style="display:grid; grid-template-columns: 325px auto; grid-column-gap: 50px; padding:0.75rem">
                                <div v-if="elevatorMotor_Machine_Selection_Shown==false">
                                    <div style="background-color:#f5f5f5; padding:0.75rem;">
                                        <div>
                                            <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e">{{$t('3007')}}</div>
                                            <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start">
                                                <div class="ZAfont_family ZAfont_size">{{$t('3076')}}:</div>
                                                <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{ropePulleyDiameter}} mm</div>
                                            </div>
                                            <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start; margin-top:7px">
                                                <div class="ZAfont_family ZAfont_size">{{$t('3077')}}:</div>
                                                <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{numberOfRopePulleysWithUniformBending}} {{$t('3078')}}</div>
                                            </div>
                                            <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start; margin-top:7px">
                                                <div class="ZAfont_family ZAfont_size">{{$t('3079')}}:</div>
                                                <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{numberOfRopePulleysWithReverseBending}} {{$t('3078')}}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e" style="margin-top:20px">{{$t('411')}}</div>
                                            <div v-if="(((sheaveProfile=='circular undercut groove')&&(sheaveHardened==false))||((sheaveProfile=='V-groove with undercut')&&(sheaveHardened==false))||((sheaveProfile=='belt')&&(sheaveHardened==false))||((sheaveProfile=='circular groove')&&(sheaveHardened==false))||((sheaveProfile=='V-groove')&&(sheaveHardened==true))||((sheaveProfile=='circular undercut groove')&&(sheaveHardened==true))||((sheaveProfile=='V-groove with undercut')&&(sheaveHardened==true)))">
                                                <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start">
                                                    <div class="ZAfont_family ZAfont_size">{{$t('3080')}}:</div>
                                                    <div v-if="((sheaveProfile=='circular undercut groove')&&(sheaveHardened==false))" class="ZAfont_family ZAfont_size" style="justify-self:end">{{$t('1131')}}</div>
                                                    <div v-if="((sheaveProfile=='V-groove with undercut')&&(sheaveHardened==false))" class="ZAfont_family ZAfont_size" style="justify-self:end">{{$t('1133')}}</div>
                                                    <div v-if="((sheaveProfile=='belt')&&(sheaveHardened==false))" class="ZAfont_family ZAfont_size" style="justify-self:end">{{$t('1360')}}</div>
                                                    <div v-if="((sheaveProfile=='circular groove')&&(sheaveHardened==false))" class="ZAfont_family ZAfont_size" style="justify-self:end">{{$t('1424')}}</div>
                                                    <div v-if="((sheaveProfile=='V-groove')&&(sheaveHardened==true))" class="ZAfont_family ZAfont_size" style="justify-self:end">{{$t('1439')}}</div>
                                                    <div v-if="((sheaveProfile=='circular undercut groove')&&(sheaveHardened==true))" class="ZAfont_family ZAfont_size" style="justify-self:end">{{$t('1440')}}</div>
                                                    <div v-if="((sheaveProfile=='V-groove with undercut')&&(sheaveHardened==true))" class="ZAfont_family ZAfont_size" style="justify-self:end">{{$t('1441')}}</div>
                                                </div>
                                            </div>
                                            <div v-if="undercutAngle_Disabled==false">
                                                <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start; margin-top:7px">
                                                    <div class="ZAfont_family ZAfont_size">{{$t('1134')}}:</div>
                                                    <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{undercutAngle}} °</div>
                                                </div>
                                            </div>
                                            <div v-if="vgrooveAngle_Disabled==false">
                                                <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start; margin-top:7px">
                                                    <div class="ZAfont_family ZAfont_size">{{$t('1135')}}:</div>
                                                    <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{vgrooveAngle}} °</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="((minimumValues_Disabled==false)||(shortenedBufferStroke_Disabled==false))">
                                            <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e" style="margin-top:20px">{{$t('3081')}}</div>
                                            <div v-if="minimumValues_Disabled==false">
                                                <div style="display:grid; grid-template-columns:22px auto;">
                                                    <span>
                                                        <input type="checkbox" v-model="calculatedWithMinimumValues" disabled />
                                                    </span>
                                                    <span class="ZAfont_family ZAfont_size"> {{$t('1128')}}</span>
                                                </div>
                                            </div>
                                            <div v-if="shortenedBufferStroke_Disabled==false">
                                                <div style="display:grid; grid-template-columns:22px auto;">
                                                    <span>
                                                        <input type="checkbox" v-model="calculatedWithShortenedBufferStroke" disabled />
                                                    </span>
                                                    <span class="ZAfont_family ZAfont_size"> {{$t('3216')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="background-color: #f5f5f5">
                                            <div style="display: grid; grid-template-columns: auto auto; column-gap: 10px; align-items: end; justify-items: start; margin-top: 30px">
                                                <div class="ZAfont_family" style="grid-column: 1 / 1; grid-row: 1 / 1;">{{$t('1050')}}:</div>
                                                <div class="ZAfont_family" style="padding-left: 25px; grid-column: 1 / 1; grid-row: 2 / 2;">{{$t('3274')}}:</div>
                                                <div class="ZAfont_family" style="grid-column: 2 / 2; grid-row: 2 / 2; justify-self: end">{{minimumCarWeight}} kg</div>
                                                <div class="ZAfont_family" style="padding-left: 25px; grid-column: 1 / 1; grid-row: 3 / 3;">{{$t('3275')}}:</div>
                                                <div class="ZAfont_family" style="grid-column: 2 / 2; grid-row: 3 / 3; justify-self: end">{{maximumCarWeight}} kg</div>
                                            </div>
                                            <div v-if="wrappingAngle != null" style="display: grid; grid-template-columns: auto auto; column-gap: 10px; align-items: end; justify-items: start; margin-top: 7px">
                                                <div class="ZAfont_family">{{$t('1073')}}:</div>
                                                <div class="ZAfont_family" style="justify-self: end">{{wrappingAngle}} °</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="elevatorMotor_Machine_Selection_Shown==true">
                                    <div style="border: 2px solid rgb(206, 210, 216); padding: 15px;">
                                        <div>
                                            <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e">{{$t('3007')}}</div>
                                            <div>
                                                <div class="ZAfont_family ZAfont_size">{{$t('3076')}}:</div>
                                                <div class="input-group">
                                                    <input v-model="ropePulleyDiameter" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: ropePulleyDiameter_ValidationResult.invalid==true}" />
                                                    <select v-model="ropePulleyDiameter_select" class="my_input_combobox_select my_select">
                                                        <option v-for="ropePulleyDiameter in tractionCheck_Inputs_ValueListsAndSelections.ropePulleyDiameter_ValueList">{{ropePulleyDiameter}}</option>
                                                    </select>
                                                    <div class="input-group-append">
                                                        <button class="btn my_input_combobox_button"><span class="fas fa-chevron-down"></span></button>
                                                        <div class="input-group-text my_input_unit ZAfont_family">mm</div>
                                                    </div>
                                                </div>
                                                <InvalidityReasons v-if="ropePulleyDiameter_ValidationResult.invalid" v-bind:validationResult="ropePulleyDiameter_ValidationResult"></InvalidityReasons>
                                            </div>
                                            <div>
                                                <div class="ZAfont_family ZAfont_size" style="margin-top:10px">{{$t('3077')}}:</div>
                                                <div class="input-group">
                                                    <input v-model="numberOfRopePulleysWithUniformBending" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: numberOfRopePulleysWithUniformBending_ValidationResult.invalid==true}" />
                                                    <select v-model="numberOfRopePulleysWithUniformBending_select" class="my_input_combobox_select my_select">
                                                        <option v-for="numberOfRopePulleysWithUniformBending in tractionCheck_Inputs_ValueListsAndSelections.numberOfRopePulleysWithUniformBending_ValueList">{{numberOfRopePulleysWithUniformBending}}</option>
                                                    </select>
                                                    <div class="input-group-append">
                                                        <button class="btn my_input_combobox_button"><span class="fas fa-chevron-down"></span></button>
                                                        <div class="input-group-text my_input_unit ZAfont_family">{{$t('3078')}}</div>
                                                    </div>
                                                </div>
                                                <InvalidityReasons v-if="numberOfRopePulleysWithUniformBending_ValidationResult.invalid" v-bind:validationResult="numberOfRopePulleysWithUniformBending_ValidationResult"></InvalidityReasons>
                                            </div>
                                            <div>
                                                <div class="ZAfont_family ZAfont_size" style="margin-top:10px">{{$t('3079')}}:</div>
                                                <div class="input-group">
                                                    <input v-model="numberOfRopePulleysWithReverseBending" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: numberOfRopePulleysWithReverseBending_ValidationResult.invalid==true}" />
                                                    <select v-model="numberOfRopePulleysWithReverseBending_select" class="my_input_combobox_select my_select">
                                                        <option v-for="numberOfRopePulleysWithReverseBending in tractionCheck_Inputs_ValueListsAndSelections.numberOfRopePulleysWithReverseBending_ValueList">{{numberOfRopePulleysWithReverseBending}}</option>
                                                    </select>
                                                    <div class="input-group-append">
                                                        <button class="btn my_input_combobox_button"><span class="fas fa-chevron-down"></span></button>
                                                        <div class="input-group-text my_input_unit ZAfont_family">{{$t('3078')}}</div>
                                                    </div>
                                                </div>
                                                <InvalidityReasons v-if="numberOfRopePulleysWithReverseBending_ValidationResult.invalid" v-bind:validationResult="numberOfRopePulleysWithReverseBending_ValidationResult"></InvalidityReasons>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e" style="margin-top:20px">{{$t('411')}}</div>
                                            <div>
                                                <div class="ZAfont_family ZAfont_size">{{$t('3080')}}:</div>
                                                <select v-model="sheaveProfileAndSheaveHardenedTextKey" class="my_select" style="width:100%">
                                                    <option v-for="sheaveProfileAndSheaveHardened in tractionCheck_Inputs_ValueListsAndSelections.sheaveProfileAndSheaveHardened_Selection" v-bind:value="sheaveProfileAndSheaveHardened.textKey">{{$t(sheaveProfileAndSheaveHardened.textKey)}}</option>
                                                </select>
                                            </div>
                                            <div v-if="undercutAngle_Disabled==false">
                                                <div v-if="undercutWidth_Input==false">
                                                    <div class="ZAfont_family ZAfont_size" style="margin-top:10px">{{$t('1134')}}: <span v-if="undercutWidth_Disabled==false && undercutWidth!=null">({{ValueDecimalSeparator(undercutWidth)}} mm)</span></div>
                                                    <div class="my_input-group">
                                                        <select v-model="undercutAngle" class="my_select" style="width:100%">
                                                            <option v-for="undercutDetail in tractionCheck_Inputs_ValueListsAndSelections.undercutAngle_ValueList">{{undercutDetail.angle}}</option>
                                                        </select>
                                                        <div class="my_input-group_unit ZAfont_family">°</div>
                                                    </div>
                                                </div>
                                                <div v-if="undercutWidth_Input==true">
                                                    <div class="ZAfont_family ZAfont_size" style="margin-top:10px">{{$t('1134')}}: <span v-if="undercutWidth_Disabled==false">({{undercutAngle}} °)</span></div>
                                                    <div class="my_input-group">
                                                        <select v-model="undercutWidth" class="my_select" style="width:100%">
                                                            <option v-for="undercutDetail in tractionCheck_Inputs_ValueListsAndSelections.undercutAngle_ValueList">{{ValueDecimalSeparator(undercutDetail.width)}}</option>
                                                        </select>
                                                        <div class="my_input-group_unit ZAfont_family">mm</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="vgrooveAngle_Disabled==false">
                                                <div class="ZAfont_family ZAfont_size" style="margin-top:10px">{{$t('1135')}}: </div>
                                                <div class="my_input-group">
                                                    <select v-model="vgrooveAngle" class="my_select" style="width:100%">
                                                        <option v-for="vgrooveAngle in tractionCheck_Inputs_ValueListsAndSelections.vgrooveAngle_ValueList">{{vgrooveAngle}}</option>
                                                    </select>
                                                    <div class="my_input-group_unit ZAfont_family">°</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="((minimumValues_Disabled==false)||(shortenedBufferStroke_Disabled==false))">
                                            <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e" style="margin-top:20px">{{$t('3081')}}</div>
                                            <div v-if="minimumValues_Disabled==false">
                                                <div style="display:grid; grid-template-columns:22px auto;">
                                                    <span>
                                                        <input type="checkbox" v-model="calculatedWithMinimumValues" />
                                                    </span>
                                                    <span class="ZAfont_family ZAfont_size"> {{$t('1128')}}</span>
                                                </div>
                                            </div>
                                            <div v-if="shortenedBufferStroke_Disabled==false">
                                                <div style="display:grid; grid-template-columns:22px auto;">
                                                    <span>
                                                        <input type="checkbox" v-model="calculatedWithShortenedBufferStroke" />
                                                    </span>
                                                    <span class="ZAfont_family ZAfont_size"> {{$t('3216')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="background-color: #f5f5f5;">
                                            <div style="display: grid; grid-template-columns: auto auto; column-gap: 10px; align-items: end; justify-items: start; margin-top: 30px">
                                                <div class="ZAfont_family" style="grid-column: 1 / 1; grid-row: 1 / 1;">{{$t('1050')}}:</div>
                                                <div class="ZAfont_family" style="padding-left: 25px; grid-column: 1 / 1; grid-row: 2 / 2;">{{$t('3274')}}:</div>
                                                <div class="ZAfont_family" style="grid-column: 2 / 2; grid-row: 2 / 2; justify-self: end">{{minimumCarWeight}} kg</div>
                                                <div class="ZAfont_family" style="padding-left: 25px; grid-column: 1 / 1; grid-row: 3 / 3;">{{$t('3275')}}:</div>
                                                <div class="ZAfont_family" style="grid-column: 2 / 2; grid-row: 3 / 3; justify-self: end">{{maximumCarWeight}} kg</div>
                                            </div>
                                            <div v-if="wrappingAngle != null" style="display: grid; grid-template-columns: auto auto; column-gap: 10px; align-items: end; justify-items: start; margin-top: 7px">
                                                <div class="ZAfont_family">{{$t('1073')}}:</div>
                                                <div class="ZAfont_family" style="justify-self: end">{{wrappingAngle}} °</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tractionCheck_Inputs_Invalid==false">
                                    <div v-if="loader_forLoadingResults_at_tractionCheck_Modal_Shown==true">
                                        <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                                    </div>
                                    <div v-else>
                                        <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e">{{$t('3217')}}</div>
                                        <table style="width:100%">
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3082')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{$t('3085')}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">{{$t('3084')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{$t('3083')}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">{{$t('3086')}}</td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3218')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionLoadingRopeForce)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size"><=</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionLoadingTractionCapacity)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterionLoadingCheckResult == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterionLoadingCheckResult == 1 || tractionCheck.checkCriterionLoadingCheckResult == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3219')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionEmergencyStopRopeForce)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size"><=</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionEmergencyStopTractionCapacity)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterionEmergencyStopCheckResult == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterionEmergencyStopCheckResult == 1 || tractionCheck.checkCriterionEmergencyStopCheckResult == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3220')}}</td>
                                                <td v-if="ValueDecimalSeparator(tractionCheck.checkCriterionBlockedRopeForce) != 99999" style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionBlockedRopeForce)}}</td>
                                                <td v-if="ValueDecimalSeparator(tractionCheck.checkCriterionBlockedRopeForce) == 99999" style="width:20%" class="ZAfont_family ZAfont_size">T1/0</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">></td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionBlockedTractionCapacity)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterionBlockedCheckResult == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterionBlockedCheckResult == 1 || tractionCheck.checkCriterionBlockedCheckResult == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                        </table>
                                        <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e" style="margin-top:20px">{{$t('3087')}}</div>
                                        <table style="width:100%">
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3082')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{$t('3083')}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">{{$t('3084')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{$t('3085')}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">{{$t('3086')}}</td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('1148')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionRopeSafetyCalculatedValue)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">></td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionRopeSafetyLimitValue)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterionRopeSafetyCheckResult == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterionRopeSafetyCheckResult == 1 || tractionCheck.checkCriterionRopeSafetyCheckResult == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3221')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionRopeSafetyFactorCalculatedValue)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">></td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionRopeSafetyFactorLimitValue)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterionRopeSafetyFactorCheckResult == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterionRopeSafetyFactorCheckResult == 1 || tractionCheck.checkCriterionRopeSafetyFactorCheckResult == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                        </table>
                                        <div style="background-color:#f5f5f5; padding:0.75rem; margin-top:5px">
                                            <div class="ZAfont_family ZAfont_size ZAcolor_blue" style="font-weight:700">{{$t('3088')}}</div>
                                            <div class="ZAfont_family ZAfont_size">{{$t('3263')}}: {{tractionCheck.equivalentNumberOfTractionSheaves}}</div>
                                            <div class="ZAfont_family ZAfont_size">{{$t('3264')}}: {{tractionCheck.equivalentNumberOfPulleyRopes}}</div>
                                            <div class="ZAfont_family ZAfont_size">{{$t('3265')}} {{tractionCheck.equivalentNumberOfPulleys}}</div>
                                        </div>
                                        <hr />
                                        <div class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e" style="margin-top:20px">{{$t('3089')}}<span v-if="tractionCheck.showAdditionalValuesVgrooveWithUndercut == true"> {{$t('52')}}</span></div>
                                        <table style="width:100%">
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3082')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{$t('3085')}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">{{$t('3084')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{$t('3083')}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">{{$t('3086')}}</td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3222')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionCarEmptyOnTopAcceleratingRopeForce)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size"><=</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionCarEmptyOnTopAcceleratingTractionCapacity)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterionCarEmptyOnTopAcceleratingCheckResult == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterionCarEmptyOnTopAcceleratingCheckResult == 1 || tractionCheck.checkCriterionCarEmptyOnTopAcceleratingCheckResult == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3223')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterion150percentNominalLoadAtBottomNotMovingRopeForce)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size"><=</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterion150percentNominalLoadAtBottomNotMovingTractionCapacity)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterion150percentNominalLoadAtBottomNotMovingCheckResult == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterion150percentNominalLoadAtBottomNotMovingCheckResult == 1 || tractionCheck.checkCriterion150percentNominalLoadAtBottomNotMovingCheckResult == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3224')}}<sup><i class="fal fa-asterisk" style="font-size:10px"></i></sup></td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionRopePressureCalculatedValue)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size"><</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionRopePressureLimitValue)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterionRopePressureCheckResult == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterionRopePressureCheckResult == 1 || tractionCheck.checkCriterionRopePressureCheckResult == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                        </table>
                                        <div v-if="tractionCheck.showAdditionalValuesVgrooveWithUndercut==true" class="my_fontSize_1Point2 my_fontWeight_600 ZAfont_family my_color_00338e" style="margin-top:20px">{{$t('3089')}} {{$t('53')}}</div>
                                        <table v-if="tractionCheck.showAdditionalValuesVgrooveWithUndercut==true" style="width:100%">
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3082')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{$t('3085')}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">{{$t('3084')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{$t('3083')}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">{{$t('3086')}}</td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3222')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionCarEmptyOnTopAcceleratingRopeForce_Additional)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size"><=</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionCarEmptyOnTopAcceleratingTractionCapacity_Additional)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterionCarEmptyOnTopAcceleratingCheckResult_Additional == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterionCarEmptyOnTopAcceleratingCheckResult_Additional == 1 || tractionCheck.checkCriterionCarEmptyOnTopAcceleratingCheckResult_Additional == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3223')}}</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterion150percentNominalLoadAtBottomNotMovingRopeForce_Additional)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size"><=</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterion150percentNominalLoadAtBottomNotMovingTractionCapacity_Additional)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterion150percentNominalLoadAtBottomNotMovingCheckResult_Additional == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterion150percentNominalLoadAtBottomNotMovingCheckResult_Additional == 1 || tractionCheck.checkCriterion150percentNominalLoadAtBottomNotMovingCheckResult_Additional == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%" class="ZAfont_family ZAfont_size">{{$t('3224')}}<sup><i class="fal fa-asterisk" style="font-size:10px"></i></sup></td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionRopePressureCalculatedValue_Additional)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size"><</td>
                                                <td style="width:20%" class="ZAfont_family ZAfont_size">{{ValueDecimalSeparator(tractionCheck.checkCriterionRopePressureLimitValue_Additional)}}</td>
                                                <td style="width:15%" class="ZAfont_family ZAfont_size">
                                                    <span v-if="tractionCheck.checkCriterionRopePressureCheckResult_Additional == 0" class="far fa-times" style="color:red; font-weight:700"></span>
                                                    <span v-if="tractionCheck.checkCriterionRopePressureCheckResult_Additional == 1 || tractionCheck.checkCriterionRopePressureCheckResult_Additional == 2" class="far fa-check" style="color:#228B22; font-weight:700"></span>
                                                </td>
                                            </tr>
                                        </table>
                                        <div class="ZAfont_family" style="margin-top:20px;"><sup><span class="fal fa-asterisk" style="font-size:10px"></span></sup>{{$t('3262')}}</div>
                                        <div v-if="tractionCheck.safetyCounter!=null && tractionCheck.safetyCounter==true" class="ZAfont_family" style="margin-top:20px; color:red"><sup><span style="font-size:10px"></span></sup>{{$t('420')}} Max. trips: {{tractionCheck.maximumNumberOfTrips}}</div>
                                    </div>
                                </div>
                                <div v-else>
                                    <InvalidInputs></InvalidInputs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my_modal-footer" style="align-items:flex-end; justify-content:flex-end;">
                        <div v-if="elevatorMotor_Machine_Selection_Shown==false">
                            <button @click="tractionCheck_Modal_Shown=false" class="btn my_btn_standard">{{$t('3090')}}</button>
                        </div>
                        <div v-if="elevatorMotor_Machine_Selection_Shown==true">
                            <button @click="tractionCheck_Modal_Shown=false" class="btn my_btn_standard">{{$t('1018')}}</button>
                            <button @click="tractionCheck_Modal_Continue=true" class="btn my_btn_standard" style="margin-left: 5px">{{$t('1023')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my_modal" v-bind:class="{show: wrappingAngleCalculation_Modal_Shown==true && frameCalculation_Modal_Shown == false}" align="center">
            <div class="my_modal-dialog size-sm">
                <div class="my_modal-content">
                    <div class="my_modal-header">
                        <div class="my_fontSize_1Point5 my_fontWeight_600 my_color_00338e ZAfont_family">{{$t('3291')}}</div>
                        <div>
                            <span @click="wrappingAngleCalculation_Modal_Shown=false" class="fal fa-times fa-2x"></span>
                        </div>
                    </div>
                    <div class="my_modal-body" style="max-height: calc(100vh - 150px)">
                        <div v-if="elevatorMotor_Machine_Selection_Shown==true">
                            <div v-if="wrappingAngle_Calculated==true">
                                <div v-if="loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown==true" align="center">
                                    <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                                </div>
                                <div v-if="loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown==false">
                                    <div align="center">
                                        <!--<img src="../../../../public/img/Frame/FrameDimensions1.png" style="width:calc(100% / 2)" />-->
                                        <img src="../../../../public/img/ProductImages/Frame/FrameDimensions2.png" style="width: calc(100% / 2)" />
                                    </div>
                                    <!--<a v-if="wrappingAngleFrameCalculation_Modal_Available == true" @click="change_frame_Calculated_Show(true)" class="ZAfont_family ZAfont_size" href="#">Mit {{$t('3255')}}</a>-->
                                    <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start;background-color: #f5f5f5">
                                        <div class="ZAfont_family ZAfont_size">{{$t('1089')}}: </div>
                                        <div style="font-size: 16px; justify-self: end">{{motorDesignation_WrappingAngleCalculation}}</div>
                                    </div>
                                    <div style="margin-bottom: 10px; display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start; background-color: #f5f5f5;">
                                        <div class="ZAfont_family ZAfont_size">{{$t('1028')}}: </div>
                                        <div style="font-size: 16px; justify-self: end">{{tractionSheaveDiameter_WrappingAngleCalculation}} mm</div>
                                    </div>
                                    <div>
                                        <div class="ZAfont_family ZAfont_size">{{$t('1026')}}</div>
                                        <div class="my_input-group">
                                            <input v-model="verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult.invalid==true}" />
                                            <div class="my_input-group_unit ZAfont_family">mm</div>
                                        </div>
                                        <InvalidityReasons v-if="verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult.invalid" v-bind:validationResult="verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult"></InvalidityReasons>
                                    </div>
                                    <div style="margin-top:10px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('1027')}}</div>
                                        <div class="my_input-group">
                                            <input v-model="horizontalDistanceOfTheRopeHoles" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: horizontalDistanceOfTheRopeHoles_ValidationResult.invalid==true}" />
                                            <div class="my_input-group_unit ZAfont_family">mm</div>
                                        </div>
                                        <InvalidityReasons v-if="horizontalDistanceOfTheRopeHoles_ValidationResult.invalid" v-bind:validationResult="horizontalDistanceOfTheRopeHoles_ValidationResult"></InvalidityReasons>
                                    </div>
                                    <div style="margin-top:10px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('3076')}}</div>
                                        <div class="my_input-group">
                                            <input v-model="deflectionPulleyDiameter" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: deflectionPulleyDiameter_ValidationResult.invalid==true}" />
                                            <div class="my_input-group_unit ZAfont_family">mm</div>
                                        </div>
                                        <InvalidityReasons v-if="deflectionPulleyDiameter_ValidationResult.invalid" v-bind:validationResult="deflectionPulleyDiameter_ValidationResult"></InvalidityReasons>
                                    </div>
                                    <div v-if="loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown==true" align="center">
                                        <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                                    </div>
                                    <div v-if="loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown==false">
                                        <div v-if="(verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley!=null)&&(horizontalDistanceOfTheRopeHoles!=null)&&(deflectionPulleyDiameter!=null)&&(verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult.invalid==false)&&(horizontalDistanceOfTheRopeHoles_ValidationResult.invalid==false)&&(deflectionPulleyDiameter_ValidationResult.invalid==false)&&(calculated_wrappingAngle!=null)" style="margin-top:10px">
                                            <div class="my_font-weight_600 ZAfont_family ZAfont_size" style="background-color:#f5f5f5; padding:0.75rem; margin-top:10px">{{$t('3294')}}: <span class="my_fontSize_1Point5 my_font-weight_700">{{calculated_wrappingAngle}}°</span></div>
                                        </div>
                                        <div v-if="!((verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley!=null)&&(horizontalDistanceOfTheRopeHoles!=null)&&(deflectionPulleyDiameter!=null)&&(verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult.invalid==false)&&(horizontalDistanceOfTheRopeHoles_ValidationResult.invalid==false)&&(deflectionPulleyDiameter_ValidationResult.invalid==false)&&(calculated_wrappingAngle==null))" style="margin-top:10px">
                                            <a @click="change_wrappingAngle_Calculated(false)" class="ZAfont_family ZAfont_size" href="#">{{$t('3297')}}</a>
                                        </div>
                                        <div v-if="(verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley!=null)&&(horizontalDistanceOfTheRopeHoles!=null)&&(deflectionPulleyDiameter!=null)&&(verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult.invalid==false)&&(horizontalDistanceOfTheRopeHoles_ValidationResult.invalid==false)&&(deflectionPulleyDiameter_ValidationResult.invalid==false)&&(calculated_wrappingAngle==null)" style="margin-top:10px">
                                            <div style="display:grid; grid-template-columns:auto auto; grid-gap:10px; background-color:#cae1ff; padding:0.75rem;">
                                                <div><span class="fal fa-info-square fa-3x my_color_00338e"></span></div>
                                                <div class="ZAfont_family ZAfont_size">
                                                    <div>{{$t('3259')}}</div>
                                                    <div style="margin-top:10px">{{$t('3258')}}</div>
                                                    <div>{{$t('3292')}} <a @click="change_wrappingAngle_Calculated(false)" class="ZAfont_family ZAfont_size" href="#">{{$t('3293')}}</a>.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="wrappingAngle_Calculated==false">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('1404')}}</div>
                                    <div class="my_input-group">
                                        <input v-model="entered_wrappingAngle" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: entered_wrappingAngle_ValidationResult.invalid==true}" />
                                        <div class="my_input-group_unit ZAfont_family">°</div>
                                    </div>
                                    <InvalidityReasons v-if="entered_wrappingAngle_ValidationResult.invalid" v-bind:validationResult="entered_wrappingAngle_ValidationResult"></InvalidityReasons>
                                </div>
                                <div style="margin-top:10px">
                                    <a @click="change_wrappingAngle_Calculated(true)" class="ZAfont_family ZAfont_size" href="#">{{$t('3296')}}</a>
                                </div>
                            </div>
                        </div>
                        <div v-if="elevatorMotor_Machine_Selection_Shown==false">
                            <div style="background-color:#f5f5f5; padding:0.75rem;">
                                <div v-if="frameType_WrappingAngleCalculation != null" style="display: grid; grid-template-columns: auto auto; column-gap: 10px; align-items: end; justify-items: start; margin-top: 7px; background-color: #f5f5f5;">
                                    <div class="ZAfont_family ZAfont_size">{{$t('3255')}}: </div>
                                    <div style="font-size: 16px; justify-self: end">{{frameTypeDesignation_WrappingAngleCalculation}} {{frameType_WrappingAngleCalculation}}</div>
                                </div>
                                <div v-if="deflectionPulleyDiameter!=null && deflectionPulleyDiameter>0">
                                    <div style="display: grid; grid-template-columns: auto auto; column-gap: 10px; align-items: end; justify-items: start;margin-top:7px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('1026')}}:</div>
                                        <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{this.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley}} mm</div>
                                    </div>
                                    <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start; margin-top:7px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('1027')}}:</div>
                                        <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{this.horizontalDistanceOfTheRopeHoles}} mm</div>
                                    </div>
                                    <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start; margin-top:7px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('3076')}}:</div>
                                        <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{this.deflectionPulleyDiameter}} mm</div>
                                    </div>
                                    <hr />
                                    <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start">
                                        <div class="my_font-weight_600 ZAfont_family ZAfont_size">{{$t('3294')}}:</div>
                                        <div class="my_font-weight_600 ZAfont_family ZAfont_size" style="justify-self:end">{{this.calculated_wrappingAngle}} °</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="my_modal-footer" style="justify-content:flex-end">
                        <div>
                            <button v-if="((elevatorMotor_Machine_Selection_Shown==false)||((elevatorMotor_Machine_Selection_Shown==true) && ((loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown==true) || (loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown==true))))" @click="wrappingAngleCalculation_Modal_Shown=false" class="btn my_btn_standard">{{$t('3090')}}</button>
                            <button v-if="(elevatorMotor_Machine_Selection_Shown==true) && (loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown==false) && (loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown==false)" @click="show_tractionCheck_Modal" class="btn my_btn_standard">{{$t('1023')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div style="position:fixed; bottom:55px;left:5px">
            <button @click="openHelpFile" style="width:25px; height:25px; border-radius: 50%; background-color: #00338e; border: none; color: white; text-align: center; display: inline-block; font-size: 14px; cursor: pointer; font-weight: bold">i</button>
        </div>-->
        <div class="my_modal" v-bind:class="{show: wrappingAngleCalculation_Modal_Shown==true && frameCalculation_Modal_Shown == true}" align="center">
            <div class="my_modal-dialog size-lg">
                <div class="my_modal-content">
                    <div class="my_modal-header">
                        <div class="my_fontSize_1Point5 my_fontWeight_600 my_color_00338e ZAfont_family">{{$t('3291')}}</div>
                        <div>
                            <span @click="wrappingAngleCalculation_Modal_Shown=false" class="fal fa-times fa-2x"></span>
                        </div>
                    </div>
                    <div class="my_modal-body" style="max-height: calc(100vh - 150px)">
                        <div v-if="elevatorMotor_Machine_Selection_Shown==true">
                            <div v-if="loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown==true" align="center">
                                <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                            </div>
                            <div v-if="loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown==false">
                                <div style="display: grid; grid-template-columns: 25% auto; padding: 3px; overflow: auto">
                                    <div align="left">
                                        <!--<img src="../../../../public/img/Frame/FrameDimensions1.png" style="width:calc(100% / 2)" />-->
                                        <!--<img src="../../../../public/img/Frame/FrameDimensions2.png" style="width: calc(100%)" />-->
                                        <Images v-if="frame_ImagePath!=null" :imageFilePath="frame_ImagePath" :maxImageHeight="0" :maxImageWidth="0"></Images>
                                    </div>
                                    <div>
                                        <!--<a @click="change_frame_Calculated_Show(false)" class="ZAfont_family ZAfont_size" href="#">Ohne {{$t('3255')}}</a>-->
                                        <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start;background-color: #f5f5f5">
                                            <div class="ZAfont_family ZAfont_size">{{$t('1089')}}: </div>
                                            <div style="font-size: 16px; justify-self: end">{{motorDesignation_WrappingAngleCalculation}}</div>
                                        </div>
                                        <div style="margin-bottom: 10px; display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start; background-color: #f5f5f5;">
                                            <div class="ZAfont_family ZAfont_size">{{$t('1028')}}: </div>
                                            <div style="font-size: 16px; justify-self: end">{{tractionSheaveDiameter_WrappingAngleCalculation}} mm</div>
                                        </div>
                                        <div>
                                            <ul class="my_frame">
                                                <li style="cursor: pointer" v-for="frame in frame_Selection" @click.prevent="change_Selected_at_frame_Selection(frame)" v-bind:class="{active: frame.selected==true}">
                                                    <div style="display: grid; grid-template-columns: auto auto auto auto auto auto auto auto; padding: 3px; overflow: auto">
                                                        <div class="my_div_minWidth_100" style="margin-right:10px">
                                                            <div class="my_fontSize_1Point1 ZAfont_family my_color_00338e">
                                                                <span class="my_font-weight_600" v-if="frame.typeDesignation==null || frame.typeDesignation.useTextKey==false">{{frame.frame.typeDesignation}}</span>
                                                                <span class="my_font-weight_600" v-if="frame.typeDesignation!=null && frame.typeDesignation.useTextKey==true">{{$t(frame.typeDesignation.textKey)}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                            <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{frame.frame.type}}</span></div>
                                                            <div class="my_table_parameters_infoText">{{$t('407')}}</div>
                                                        </div>
                                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                            <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{frame.frame.verticalDistance}}</span> mm</div>
                                                            <div class="my_table_parameters_infoText">{{$t('1026')}}</div>
                                                        </div>
                                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                            <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{frame.frame.horizontalDistanceMinimum}}</span> mm</div>
                                                            <div class="my_table_parameters_infoText">ASL min</div>
                                                        </div>
                                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                            <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{frame.frame.horizontalDistanceMaximum}}</span> mm</div>
                                                            <div class="my_table_parameters_infoText">ASL max</div>
                                                        </div>
                                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                            <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{frame.frame.pulleyDiameter}}</span> mm</div>
                                                            <div class="my_table_parameters_infoText">{{$t('1142')}}</div>
                                                        </div>
                                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                            <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{frame.frame.dimensionSheet}}</span> </div>
                                                            <div class="my_table_parameters_infoText">{{$t('1193')}}</div>
                                                        </div>
                                                        <div v-if="frame.positionPulleyTextKey != null" class="my_div_minWidth_100 my_div_maginleft_5">
                                                            <div class="my_fontSize_1Point1"><span class="my_font-weight_600"> {{$t(frame.positionPulleyTextKey)}}</span> </div>
                                                            <div class="my_table_parameters_infoText">{{$t('3383')}}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!--<div v-if="frameInformation_Show==true" style="display:grid; grid-template-columns:auto auto">
                                    <div>
                                        <div v-for="statusInformation in frameInformation.status.statusInformations">
                                            <div class="ZAfont_family ZAfont_size" style="color:red; font-weight:bold">{{$t(statusInformation.textKey, statusInformation.textValues)}}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <button @click="back_to_input" class="btn my_btn_standard">{{$t('3314')}}</button>
                                    </div>
                                </div>-->
                                <div v-if="horizontalDistanceOfTheRopeHoles_Show==true">
                                    <div class="ZAfont_family ZAfont_size">{{$t('1026')}}</div>
                                    <div class="my_input-group">
                                        <input v-model="verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult.invalid==true}" disabled />
                                        <div class="my_input-group_unit ZAfont_family">mm</div>
                                    </div>
                                    <InvalidityReasons v-if="verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult.invalid" v-bind:validationResult="verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult"></InvalidityReasons>
                                </div>
                                <div v-if="horizontalDistanceOfTheRopeHoles_Show==true" style="margin-top:10px">
                                    <div class="ZAfont_family ZAfont_size">{{$t('1027')}}</div>
                                    <div class="my_input-group">
                                        <input v-model="horizontalDistanceOfTheRopeHoles" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: horizontalDistanceOfTheRopeHoles_ValidationResult.invalid==true}" />
                                        <div class="my_input-group_unit ZAfont_family">mm</div>
                                    </div>
                                    <InvalidityReasons v-if="horizontalDistanceOfTheRopeHoles_ValidationResult.invalid" v-bind:validationResult="horizontalDistanceOfTheRopeHoles_ValidationResult"></InvalidityReasons>
                                </div>
                                <div v-if="horizontalDistanceOfTheRopeHoles_Show==true" style="margin-top:10px">
                                    <div class="ZAfont_family ZAfont_size">{{$t('3076')}}</div>
                                    <div class="my_input-group">
                                        <input v-model="deflectionPulleyDiameter" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: deflectionPulleyDiameter_ValidationResult.invalid==true}" disabled />
                                        <div class="my_input-group_unit ZAfont_family">mm</div>
                                    </div>
                                    <InvalidityReasons v-if="deflectionPulleyDiameter_ValidationResult.invalid" v-bind:validationResult="deflectionPulleyDiameter_ValidationResult"></InvalidityReasons>
                                </div>

                                <div v-if="loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown==true" align="center">
                                    <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                                </div>
                                <div v-if="loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown==false">
                                    <div v-if="(verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley!=null)&&(horizontalDistanceOfTheRopeHoles!=null)&&(deflectionPulleyDiameter!=null)&&(verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult.invalid==false)&&(horizontalDistanceOfTheRopeHoles_ValidationResult.invalid==false)&&(deflectionPulleyDiameter_ValidationResult.invalid==false)&&(calculated_wrappingAngle!=null)" style="margin-top:10px">
                                        <div class="my_font-weight_600 ZAfont_family ZAfont_size" style="background-color:#f5f5f5; padding:0.75rem; margin-top:10px">{{$t('3294')}}: <span class="my_fontSize_1Point5 my_font-weight_700">{{calculated_wrappingAngle}}°</span></div>
                                    </div>
                                    <!--<div v-if="!((verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley!=null)&&(horizontalDistanceOfTheRopeHoles!=null)&&(deflectionPulleyDiameter!=null)&&(verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult.invalid==false)&&(horizontalDistanceOfTheRopeHoles_ValidationResult.invalid==false)&&(deflectionPulleyDiameter_ValidationResult.invalid==false)&&(calculated_wrappingAngle==null))" style="margin-top:10px">
            <a @click="change_wrappingAngle_Calculated(false)" class="ZAfont_family ZAfont_size" href="#">{{$t('3297')}}</a>
        </div>-->
                                    <div v-if="(verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley!=null)&&(horizontalDistanceOfTheRopeHoles!=null)&&(deflectionPulleyDiameter!=null)&&(verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult.invalid==false)&&(horizontalDistanceOfTheRopeHoles_ValidationResult.invalid==false)&&(deflectionPulleyDiameter_ValidationResult.invalid==false)&&(calculated_wrappingAngle==null)" style="margin-top:10px">
                                        <div style="display:grid; grid-template-columns:auto auto; grid-gap:10px; background-color:#cae1ff; padding:0.75rem;">
                                            <div><span class="fal fa-info-square fa-3x my_color_00338e"></span></div>
                                            <div class="ZAfont_family ZAfont_size">
                                                <div>{{$t('3259')}}</div>
                                                <div style="margin-top:10px">{{$t('3258')}}</div>
                                                <!--<div>{{$t('3292')}} <a @click="change_wrappingAngle_Calculated(false)" class="ZAfont_family ZAfont_size" href="#">{{$t('3293')}}</a>.</div>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="elevatorMotor_Machine_Selection_Shown==false">
                            <div style="background-color:#f5f5f5; padding:0.75rem;">
                                <div v-if="frameType_WrappingAngleCalculation != null" style="display: grid; grid-template-columns: auto auto; column-gap: 10px; align-items: end; justify-items: start; margin-top: 7px; background-color: #f5f5f5;">
                                    <div class="ZAfont_family ZAfont_size">{{$t('3255')}}: </div>
                                    <div style="font-size: 16px; justify-self: end">{{frameTypeDesignation_WrappingAngleCalculation}} {{frameType_WrappingAngleCalculation}}</div>
                                </div>
                                <div v-if="deflectionPulleyDiameter!=null && deflectionPulleyDiameter>0">
                                    <div style="display: grid; grid-template-columns: auto auto; column-gap: 10px; align-items: end; justify-items: start;margin-top:7px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('1026')}}:</div>
                                        <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{this.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley}} mm</div>
                                    </div>
                                    <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start; margin-top:7px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('1027')}}:</div>
                                        <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{this.horizontalDistanceOfTheRopeHoles}} mm</div>
                                    </div>
                                    <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start; margin-top:7px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('3076')}}:</div>
                                        <div class="ZAfont_family ZAfont_size" style="justify-self:end">{{this.deflectionPulleyDiameter}} mm</div>
                                    </div>
                                    <hr />
                                    <div style="display: grid; grid-template-columns:auto auto; column-gap:10px; align-items:end; justify-items:start">
                                        <div class="my_font-weight_600 ZAfont_family ZAfont_size">{{$t('3294')}}:</div>
                                        <div class="my_font-weight_600 ZAfont_family ZAfont_size" style="justify-self:end">{{this.calculated_wrappingAngle}} °</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="my_modal-footer" style="justify-content:flex-end">
                        <div>
                            <button v-if="((elevatorMotor_Machine_Selection_Shown==false)||((elevatorMotor_Machine_Selection_Shown==true) && ((loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown==true) || (loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown==true))))" @click="wrappingAngleCalculation_Modal_Shown=false" class="btn my_btn_standard">{{$t('3090')}}</button>
                            <button v-if="(elevatorMotor_Machine_Selection_Shown==true) && (loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown==false) && (loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown==false)" @click="show_tractionCheck_Modal" class="btn my_btn_standard">{{$t('1023')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import router from '../../../router';
    import LoaderIcon from '../../loader/LoaderIcon';
    import InvalidInputs from '../../configuration/InvalidInputs'
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import Images from '../../configuration/Images';
    import { driveTechnologies } from '../../../store/driveTechnologies.module';

    export default {
        name: 'DriveTechnologies',

        components: {
            LoaderIcon,
            InvalidInputs,
            InvalidityReasons,
            Images,
        },
        data() {
            return {
                frameCalculationModalShown: false,
            }
        },
        computed: {
            driveTechnologies_Passed: {
                get() {
                    if (this.$store.state.configurations.navigation.driveTechnologies == 'passed') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            configurationOverviewsId: {
                get() {
                    return this.$store.state.configurations.configurationOverviewsId;
                },
            },
            configurationSteps: {
                get() {
                    return this.$store.state.configurations.configurationSteps;
                },
            },
            loaderModal_Shown: {
                get() {
                    return this.$store.state.container.loaderModal_Shown;
                },
            },

            elevatorMotor_Machine_Filter_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_Filter_Shown;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_Filter_Shown_storeAction', value);
                }
            },
            fromInputs_wrappingAngle_Calculated: {
                get() {
                    return this.$store.state.inputs.wrappingAngle_Calculated;
                },
            },


            // userPreferences
            userPreference_affected_selected_elevatorMotor_TemperatureMonitoring: {
                get() {
                    return this.$store.state.driveTechnologies.userPreference_affected_selected_elevatorMotor_TemperatureMonitoring;
                },
            },
            userPreference_affected_elevatorMotor_ForcedVentilation_Selected: {
                get() {
                    return this.$store.state.driveTechnologies.userPreference_affected_elevatorMotor_ForcedVentilation_Selected;
                },
            },
            userPreferences_affected_selected_elevatorMotor_Brake: {
                get() {
                    return this.$store.state.driveTechnologies.userPreferences_affected_selected_elevatorMotor_Brake;
                },
            },
            userPreference_affected_selected_elevatorMotor_Encoder: {
                get() {
                    return this.$store.state.driveTechnologies.userPreference_affected_selected_elevatorMotor_Encoder;
                },
            },


            // elevatorMotor_Machine
            elevatorMotor_Machine_Selection_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_Selection_Shown;
                },
                set(value) {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.hasOwnProperty('elevatorMotor_Machine_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection))) {
                        let selected_machine = this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.find(i => i.selected == true);
                        if (selected_machine.lowestNominalRotationSpeedPerMachineTractionSheaveCombination == false && this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination == true) {
                            this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination_storeAction', false);
                        }
                    }
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_Selection_Shown_storeAction', value);
                }
            },
            elevatorMotor_Machine_Selection: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.hasOwnProperty('elevatorMotor_Machine_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection))) {
                        if (this.elevatorMotor_Machine_Selection_Filtered == false) {
                            return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.slice(0, this.elevatorMotor_Machine_Selection_lastIndex);
                        }
                        else {

                            return this.filtered_elevatorMotor_Machine_Selection.slice(0, this.elevatorMotor_Machine_Selection_lastIndex);
                        }
                    }
                    else {
                        return [];
                    }
                }
            },
            elevatorMotor_Machine_Selection_fullLength: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.hasOwnProperty('elevatorMotor_Machine_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.length;
                    }
                    else {
                        return 0;
                    }
                }
            },
            elevatorMotor_Machine_Selected: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.hasOwnProperty('elevatorMotor_Machine_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.some(i => i.selected == true)
                    }
                },
            },
            selected_elevatorMotor_Machine: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.hasOwnProperty('elevatorMotor_Machine_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.find(i => i.selected == true)


                    }
                    else {
                        return undefined;
                    }
                }
            },

            elevatorMotor_Machine_Selection_lastIndex: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_Selection_lastIndex;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_Selection_lastIndex_storeAction', value)
                }
            },
            filtered_elevatorMotor_Machine_Selection: {
                get() {
                    return this.$store.state.driveTechnologies.filtered_elevatorMotor_Machine_Selection;
                },
            },
            elevatorMotor_Machine_Selection_Filtered: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_Selection_Filtered;
                },
            },


            // elevatorMotor_Machine filter
            elevatorMotor_Machine_Filter_Data: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data;
                },
            },
            elevatorMotor_AutomationProject: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_AutomationProject'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_AutomationProject;
                    }
                },
            },
            elevatorMotor_ZAframe: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_ZAframe'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_ZAframe;
                    }
                },
            },
            lowestNominalRotationSpeedPerMachineTractionSheaveCombination_Available: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('lowestNominalRotationSpeedPerMachineTractionSheaveCombination_Available'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.lowestNominalRotationSpeedPerMachineTractionSheaveCombination_Available;
                    }
                },
            },
            elevatorMotor_Machine_ElectricalUtilization_LowestPossibleMaximum: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_Machine_ElectricalUtilization_LowestPossibleMaximum'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_ElectricalUtilization_LowestPossibleMaximum;
                    }
                },
            },
            elevatorMotor_Machine_ElectricalUtilization_Maximum: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_Machine_ElectricalUtilization_Maximum'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_ElectricalUtilization_Maximum;
                    }
                },
            },
            elevatorMotor_Machine_MechanicalUtilization_LowestPossibleMaximum: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_Machine_MechanicalUtilization_LowestPossibleMaximum'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_MechanicalUtilization_LowestPossibleMaximum;
                    }
                },
            },
            elevatorMotor_Machine_MechanicalUtilization_Maximum: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_Machine_MechanicalUtilization_Maximum'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_MechanicalUtilization_Maximum;
                    }
                },
            },
            elevatorMotor_TractionSheave_Diameter_Minimum: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_TractionSheave_Diameter_Minimum'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Minimum;
                    }
                },
            },
            elevatorMotor_TractionSheave_Diameter_Maximum: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_TractionSheave_Diameter_Maximum'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Maximum;
                    }
                },
            },
            elevatorMotor_TemperatureMonitoringByPT100_Available: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_TemperatureMonitoringByPT100_Available'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TemperatureMonitoringByPT100_Available;
                    }
                },
            },
            elevatorMotor_ForcedVentilation_AvailableAndOrNecessary: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_ForcedVentilation_AvailableAndOrNecessary'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_ForcedVentilation_AvailableAndOrNecessary;
                    }
                },
            },
            elevatorMotor_Brake_MechanicalRelease_Available1: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_Brake_MechanicalRelease_Available'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Brake_MechanicalRelease_Available;
                    }
                },
            },

            elevatorMotor_SafetyBrake_Available1: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.hasOwnProperty('elevatorMotor_SafetyBrake_Available'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_SafetyBrake_Available;
                    }
                },
            },

            elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection;
                },
            },
            elevatorMotor_Machine_FilterCriterion_tractionSheaveDiameter_Selection: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_tractionSheaveDiameter_Selection;
                },
            },
            elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination: {
                get() {
                    const index = this.$store.state.driveTechnologies.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => i.filterBy == 'lowestNominalRotationSpeedPerMachineTractionSheaveCombination')
                    if (index == -1 && this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination == true || index > -1 && this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination == false) {
                        this.$store.dispatch('driveTechnologies/addOrRemove_elementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'lowestNominalRotationSpeedPerMachineTractionSheaveCombination' });
                    }
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination_storeAction', value);
                }
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum: {
                get() {
                    const localDecimal = { value: this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum' }
                    this.$store.dispatch('driveTechnologies/set_decimalValue', localDecimal);
                }
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum;
                },
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum: {
                get() {
                    const localDecimal = { value: this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum' }
                    this.$store.dispatch('driveTechnologies/set_decimalValue', localDecimal);
                }
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum;
                },
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum.value;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeAction', value);
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeAction', value);
                }
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum;
                },
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum.value;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeAction', value);
                }
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum;
                },
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100_storeAction', value);
                },
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation_storeAction', value);
                },
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease_storeAction', value);
                },
            },

            elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake_storeAction', value);
                },
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject_storeAction', value);
                },
            },
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe_storeAction', value);
                },
            },
            arrayOf_active_elevatorMotor_Machine_FilterCriteria: {
                get() {
                    return this.$store.state.driveTechnologies.arrayOf_active_elevatorMotor_Machine_FilterCriteria;
                },
            },


            // wrappingAngleCalculation
            wrappingAngleCalculation_Modal_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.wrappingAngleCalculation_Modal_Shown;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_wrappingAngleCalculation_Modal_Shown_storeAction', value);
                    if (value == false) {
                        this.$store.dispatch('driveTechnologies/change_frame_Data_storeAction', {});
                    }
                }
            },
            loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown;
                },
            },
            loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown;
                },
            },

            wrappingAngle_Calculated: {
                get() {
                    return this.$store.state.driveTechnologies.wrappingAngle_Calculated;
                },
            },

            verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley: {
                get() {
                    const localDecimal = { value: this.$store.state.driveTechnologies.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley' }
                    this.$store.dispatch('driveTechnologies/set_decimalValue', localDecimal);
                }
            },
            verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley;
                },
            },
            horizontalDistanceOfTheRopeHoles: {
                get() {
                    const localDecimal = { value: this.$store.state.driveTechnologies.horizontalDistanceOfTheRopeHoles.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'horizontalDistanceOfTheRopeHoles' }
                    this.$store.dispatch('driveTechnologies/set_decimalValue', localDecimal);
                }
            },
            horizontalDistanceOfTheRopeHoles_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.horizontalDistanceOfTheRopeHoles;
                },
            },
            deflectionPulleyDiameter: {
                get() {
                    return this.$store.state.driveTechnologies.deflectionPulleyDiameter.value;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_deflectionPulleyDiameter_storeAction', value);
                }
            },
            tractionSheaveDiameter_WrappingAngleCalculation: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade != null) && (this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.hasOwnProperty('elevatorMotor_TractionSheave'))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.diameter;
                    }
                },
            },
            frameType_WrappingAngleCalculation: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.hasOwnProperty('elevatorMotor_Machine_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection))) {
                        const firstIndex_whichMeetConditions = this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => i.selected == true)
                        if (firstIndex_whichMeetConditions > -1) {
                            const motor = this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[firstIndex_whichMeetConditions];
                            if (motor.hasOwnProperty('frame')) {
                                if (motor.frame != null && motor.frame.hasOwnProperty('frame')) {
                                    return motor.frame.frame.type;
                                }
                                else {
                                    return null;
                                }
                            }
                            else {
                                return null;
                            }
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
            },
            frameTypeDesignation_WrappingAngleCalculation: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.hasOwnProperty('elevatorMotor_Machine_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection))) {
                        const firstIndex_whichMeetConditions = this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => i.selected == true)
                        if (firstIndex_whichMeetConditions > -1) {
                            const motor = this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[firstIndex_whichMeetConditions];
                            if (motor.hasOwnProperty('frame')) {
                                if (motor.frame != null && motor.frame.hasOwnProperty('frame')) {
                                    return motor.frame.frame.typeDesignation;
                                }
                                else {
                                    return null;
                                }
                            }
                            else {
                                return null;
                            }
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
            },
            motorDesignation_WrappingAngleCalculation: {
                get() {
                    if (this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade != null) {
                        if (this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.hasOwnProperty('elevatorMotor_Machine')) {
                            return this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.typeDesignation;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
            },
            deflectionPulleyDiameter_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.deflectionPulleyDiameter;
                },
            },
            wrappingAngleCalculation_Inputs_Invalid: {
                get() {
                    return this.$store.state.driveTechnologies.wrappingAngleCalculation_Inputs_Invalid;
                },
            },

            calculated_wrappingAngle: {
                get() {
                    return this.$store.state.driveTechnologies.calculated_wrappingAngle;
                },
            },

            entered_wrappingAngle: {
                get() {
                    const localDecimal = { value: this.$store.state.driveTechnologies.entered_wrappingAngle.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'entered_wrappingAngle' }
                    this.$store.dispatch('driveTechnologies/set_decimalValue', localDecimal);
                }
            },
            entered_wrappingAngle_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.entered_wrappingAngle;
                },
            },


            // tractionCheck
            tractionCheck_Modal_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.tractionCheck_Modal_Shown
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_tractionCheck_Modal_Shown_storeAction', value);
                }
            },
            tractionCheck_Modal_Continue: {
                get() {
                },
                set(value) {
                    this.change_tractionCheck_Modal_Continue(value);
                }
            },
            loader_forLoadingAllContent_at_tractionCheck_Modal_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.loader_forLoadingAllContent_at_tractionCheck_Modal_Shown;
                },
            },
            loader_forLoadingResults_at_tractionCheck_Modal_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.loader_forLoadingResults_at_tractionCheck_Modal_Shown;
                },
            },

            tractionCheck_Inputs_ValueListsAndSelections: {
                get() {
                    return this.$store.state.driveTechnologies.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges;
                }
            },
            tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded: {
                get() {
                    return this.$store.state.driveTechnologies.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded
                },
            },

            minimumCarWeight: {
                get() {
                    return this.$store.state.driveTechnologies.minimumCarWeight;
                },
            },

            maximumCarWeight: {
                get() {
                    return this.$store.state.driveTechnologies.maximumCarWeight;
                },
            },

            wrappingAngle: {
                get() {
                    if (this.$store.state.driveTechnologies.tractionCheck.hasOwnProperty('suspensionMeans')) {
                        if (this.$store.state.driveTechnologies.tractionCheck.suspensionMeans.hasOwnProperty('wrappingAngleCalculated')) {
                            if (this.$store.state.driveTechnologies.tractionCheck.suspensionMeans.wrappingAngle_Calculated == true) {
                                return this.$store.state.driveTechnologies.calculated_wrappingAngle;
                            }
                            else {
                                return this.$store.state.driveTechnologies.tractionCheck.suspensionMeans.wrappingAngle;
                            }
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
            },

            ropePulleyDiameter: {
                get() {
                    return this.$store.state.driveTechnologies.ropePulleyDiameter.value;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_ropePulleyDiameter_storeAction', value);
                }
            },
            ropePulleyDiameter_select: {
                get() {
                    if (this.$store.state.driveTechnologies.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges.hasOwnProperty('ropePulleyDiameter_ValueList')) {
                        if (this.tractionCheck_Inputs_ValueListsAndSelections.ropePulleyDiameter_ValueList.includes(this.ropePulleyDiameter)) {
                            return this.ropePulleyDiameter;
                        }
                    }
                },
                set(value) {
                    this.ropePulleyDiameter = value;
                }
            },
            ropePulleyDiameter_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.ropePulleyDiameter;
                },
            },
            numberOfRopePulleysWithUniformBending: {
                get() {
                    return this.$store.state.driveTechnologies.numberOfRopePulleysWithUniformBending.value;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_numberOfRopePulleysWithUniformBending_storeAction', value);
                }
            },
            numberOfRopePulleysWithUniformBending_select: {
                get() {
                    if (this.$store.state.driveTechnologies.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges.hasOwnProperty('numberOfRopePulleysWithUniformBending_ValueList')) {
                        if (this.tractionCheck_Inputs_ValueListsAndSelections.numberOfRopePulleysWithUniformBending_ValueList.includes(this.numberOfRopePulleysWithUniformBending)) {
                            return this.numberOfRopePulleysWithUniformBending;
                        }
                    }
                },
                set(value) {
                    this.numberOfRopePulleysWithUniformBending = value;
                }
            },
            numberOfRopePulleysWithUniformBending_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.numberOfRopePulleysWithUniformBending;
                },
            },
            numberOfRopePulleysWithReverseBending: {
                get() {
                    return this.$store.state.driveTechnologies.numberOfRopePulleysWithReverseBending.value;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_numberOfRopePulleysWithReverseBending_storeAction', value);
                }
            },
            numberOfRopePulleysWithReverseBending_select: {
                get() {
                    if (this.$store.state.driveTechnologies.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges.hasOwnProperty('numberOfRopePulleysWithReverseBending_ValueList')) {
                        if (this.tractionCheck_Inputs_ValueListsAndSelections.numberOfRopePulleysWithReverseBending_ValueList.includes(this.numberOfRopePulleysWithReverseBending)) {
                            return this.numberOfRopePulleysWithReverseBending;
                        }
                    }
                },
                set(value) {
                    this.numberOfRopePulleysWithReverseBending = value;
                }
            },
            numberOfRopePulleysWithReverseBending_ValidationResult: {
                get() {
                    return this.$store.state.driveTechnologies.numberOfRopePulleysWithReverseBending;
                },
            },
            sheaveHardened: {
                get() {
                    return this.$store.state.driveTechnologies.sheaveHardened;
                },
            },
            sheaveProfile: {
                get() {
                    return this.$store.state.driveTechnologies.sheaveProfile;
                },
            },
            sheaveProfileAndSheaveHardenedTextKey: {
                get() {
                    if (this.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded == true) {
                        const sheaveProfileAndSheaveHardened = this.tractionCheck_Inputs_ValueListsAndSelections.sheaveProfileAndSheaveHardened_Selection.find(i => ((i.sheaveHardened == this.sheaveHardened) && (i.sheaveProfile == this.sheaveProfile)))
                        if (sheaveProfileAndSheaveHardened != null) {
                            return sheaveProfileAndSheaveHardened.textKey;
                        }
                    }
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_sheaveProfileAndSheaveHardened_storeAction', value)
                }
            },
            undercutAngle_Disabled: {
                get() {
                    return this.$store.state.driveTechnologies.undercutAngle_Disabled;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_undercutAngle_Disabled_storeAction', value);
                }
            },
            undercutAngle: {
                get() {
                    return this.$store.state.driveTechnologies.undercutAngle;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_undercutAngle_storeAction', value);
                }
            },
            undercutWidth_Input: {
                get() {
                    if (this.$store.state.driveTechnologies.undercutWidth_Input != null) {
                        return this.$store.state.driveTechnologies.undercutWidth_Input;
                    }
                    else {
                        return false
                    }
                }
            },
            undercutWidth_Disabled: {
                get() {
                    if (this.$store.state.driveTechnologies.undercutWidth_Disabled != null) {
                        return this.$store.state.driveTechnologies.undercutWidth_Disabled;
                    }
                    else {
                        return false
                    }
                }
            },
            undercutWidth: {
                get() {
                    if (this.undercutWidth_Disabled == true) {
                        var valueList = this.tractionCheck_Inputs_ValueListsAndSelections.undercutAngle_ValueList;
                        var result = valueList.find(item => item.angle == this.$store.state.driveTechnologies.undercutAngle);
                        const localDecimal = { value: result.width, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                },
                set(value) {
                    var valueList = this.tractionCheck_Inputs_ValueListsAndSelections.undercutAngle_ValueList;
                    const valueDecimalCriteria = { value: value, country: this.$store.state.configurations.language, }
                    const decimalValue = this.$set_decimalValueToLocaleString(valueDecimalCriteria);
                    var result = valueList.find(item => item.width == decimalValue);

                    this.$store.dispatch('driveTechnologies/change_undercutAngle_storeAction', result.angle);
                }
            },
            vgrooveAngle_Disabled: {
                get() {
                    return this.$store.state.driveTechnologies.vgrooveAngle_Disabled;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_vgrooveAngle_Disabled_storeAction', value);
                }
            },
            vgrooveAngle: {
                get() {
                    return this.$store.state.driveTechnologies.vgrooveAngle;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_vgrooveAngle_storeAction', value);
                }
            },
            minimumValues_Disabled: {
                get() {
                    return this.$store.state.driveTechnologies.minimumValues_Disabled;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_minimumValues_Disabled_storeAction', value);
                }
            },
            calculatedWithMinimumValues: {
                get() {
                    return this.$store.state.driveTechnologies.calculatedWithMinimumValues;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_calculatedWithMinimumValues_storeAction', value);
                }
            },
            shortenedBufferStroke_Disabled: {
                get() {
                    return this.$store.state.driveTechnologies.shortenedBufferStroke_Disabled;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_shortenedBufferStroke_Disabled_storeAction', value);
                }
            },
            calculatedWithShortenedBufferStroke: {
                get() {
                    return this.$store.state.driveTechnologies.calculatedWithShortenedBufferStroke;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_calculatedWithShortenedBufferStroke_storeAction', value);
                }
            },
            tractionCheck_Inputs_Invalid: {
                get() {
                    return this.$store.state.driveTechnologies.tractionCheck_Inputs_Invalid;
                }
            },

            tractionCheck: {
                get() {
                    if (this.$store.state.driveTechnologies.tractionCheck.hasOwnProperty('tractionCheck')) {
                        return this.$store.state.driveTechnologies.tractionCheck.tractionCheck;
                    }
                    else {
                        return {};
                    }
                }
            },


            // elevatorMotor_Machine_TractionSheaveSide
            elevatorMotor_Machine_TractionSheaveSide_Selection: {
                get() {
                    if (this.$store.state.driveTechnologies.elevatorMotor_Machine_TractionSheaveSide_Data.hasOwnProperty('elevatorMotor_Machine_TractionSheaveSide_Selection')) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            elevatorMotor_Machine_TractionSheaveSide_Selected: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_TractionSheaveSide_Data.hasOwnProperty('elevatorMotor_Machine_TractionSheaveSide_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection.some(i => i.selected == true)
                    }
                },
            },
            selected_elevatorMotor_Machine_TractionSheaveSide: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection.find(i => i.selected == true);
                },
            },

            // elevatorMotor_Machine_InstallationPosition
            elevatorMotor_Machine_InstallationPosition_Selection: {
                get() {
                    if (this.$store.state.driveTechnologies.elevatorMotor_Machine_InstallationPosition_Data.hasOwnProperty('elevatorMotor_Machine_InstallationPosition_Selection')) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            elevatorMotor_Machine_InstallationPosition_Selected: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Machine_InstallationPosition_Data.hasOwnProperty('elevatorMotor_Machine_InstallationPosition_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection.some(i => i.selected == true)
                    }
                },
            },
            selected_elevatorMotor_Machine_InstallationPosition: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection.find(i => i.selected == true);
                },
            },

            // elevatorMotor_TemperatureMonitoring
            elevatorMotor_TemperatureMonitoring_Selection: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_TemperatureMonitoring_Data.hasOwnProperty('elevatorMotor_TemperatureMonitoring_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            elevatorMotor_TemperatureMonitoring_Selected: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_TemperatureMonitoring_Data.hasOwnProperty('elevatorMotor_TemperatureMonitoring_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection.some(i => i.selected == true)
                    }
                }
            },
            selected_elevatorMotor_TemperatureMonitoring: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection.find(i => i.selected == true);
                },
            },
            elevatorMotor_TemperatureMonitoring_Selection_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_TemperatureMonitoring_Selection_Shown;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_TemperatureMonitoring_Selection_Shown_storeAction', value)
                }
            },

            // elevatorMotor_ForcedVentilation
            elevatorMotor_ForcedVentilation: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_ForcedVentilation;
                },
            },
            elevatorMotor_ForcedVentilation_Options_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_ForcedVentilation_Options_Shown;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_ForcedVentilation_Options_Shown_storeAction', value)
                }
            },


            // elevatorMotor_Brake
            elevatorMotor_Brake_Selection: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.hasOwnProperty('elevatorMotor_Brake_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            elevatorMotor_Brake_Selected: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.hasOwnProperty('elevatorMotor_Brake_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.some(i => i.selected == true)
                    }
                },
            },
            selected_elevatorMotor_Brake: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.find(i => i.selected == true);
                }
            },
            elevatorMotor_Brake_Selections_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Selections_Shown;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Brake_Selections_Shown_storeAction', value)
                }
            },

            elevatorMotor_Brake_Voltage_Selection: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Voltage_Selection;
                }
            },
            selected_elevatorMotor_Brake_Voltage: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Voltage_Selection.find(i => i.selected == true);
                },
            },
            elevatorMotor_Brake_MechanicalRelease_Available: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Available;
                }
            },
            elevatorMotor_Brake_MechanicalRelease_Selection: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection;
                }
            },
            selected_elevatorMotor_Brake_MechanicalRelease: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection.find(i => i.selected == true);
                },
            },
            elevatorMotor_Brake_ReleaseMonitoring_Selection: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection;
                }
            },
            selected_elevatorMotor_Brake_ReleaseMonitoring: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection.find(i => i.selected == true);
                },
            },
            elevatorMotor_Brake_Connection_Selection: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection.filter(i => i.brakeVoltage == this.selected_elevatorMotor_Brake.elevatorMotor_Brake.switchOnVoltage && i.releaseMonitoring == this.selected_elevatorMotor_Brake.elevatorMotor_Brake.releaseMonitoring);
                },
            },
            selected_elevatorMotor_Brake_Connection: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection.find(i => i.selected == true);
                },
            },
            elevatorMotor_Brake_DeliverBowdenwire_Selection: {
                get() {
                    const firstIndex_whichMeetConditions = this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection.findIndex(i => i.selected == true);
                    if (firstIndex_whichMeetConditions > -1) {
                        const _deliver_Valuelist = this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.filter(i => i.mechanicalRelease == this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection[firstIndex_whichMeetConditions].text);
                        return _deliver_Valuelist;
                    }
                    else {
                        return [];
                    }
                },
            },
            selected_elevatorMotor_Brake_DeliverBowdenwire: {
                get() {
                    const firstIndex_whichMeetConditions = this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.find(i => i.selected == true);
                    if (firstIndex_whichMeetConditions) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.find(i => i.selected == true);
                    }
                    else {
                        return null;
                    }
                },
            },
            elevatorMotor_Brake_LengthBowdenwire_Selection: {
                get() {
                    const firstIndex_whichMeetConditions_DeliverBowdenwire = this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.selected == true);
                    if (firstIndex_whichMeetConditions_DeliverBowdenwire > -1) {
                        const _length_Valuelist = this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection.filter(i => i.length != null && i.deliver.text == this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[firstIndex_whichMeetConditions_DeliverBowdenwire].deliver.text && i.mechanicalRelease == this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[firstIndex_whichMeetConditions_DeliverBowdenwire].mechanicalRelease);
                        return _length_Valuelist;
                    }
                    else {
                        return [];
                    }
                },
            },
            selected_elevatorMotor_Brake_LengthBowdenwire: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection.find(i => i.selected == true);
                },
            },


            // elevatorMotor_Encoder
            elevatorMotor_Encoder_Selection: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Encoder_Data.hasOwnProperty('elevatorMotor_Encoder_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection;
                    }
                    else {
                        return [];
                    }

                }
            },
            elevatorMotor_Encoder_Selected: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Encoder_Data.hasOwnProperty('elevatorMotor_Encoder_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.some(i => i.selected == true)
                    }
                },
            },
            selected_elevatorMotor_Encoder: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.find(i => i.selected == true)
                }
            },
            elevatorMotor_Encoder_Selection_Shown: {
                get() {
                    return this.$store.state.driveTechnologies.elevatorMotor_Encoder_Selection_Shown;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Encoder_Selection_Shown_storeAction', value)
                }
            },

            // frame
            frame_Selection: {
                get() {
                    if ((this.$store.state.driveTechnologies.frame_Data.hasOwnProperty('frame_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.frame_Data.frame_Selection))) {
                        return this.$store.state.driveTechnologies.frame_Data.frame_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            frameCalculation_Modal_Shown: {
                get() {
                    if (this.$store.state.driveTechnologies.wrappingAngleFrameCalculation_Modal_Shown != null) {
                        return this.$store.state.driveTechnologies.wrappingAngleFrameCalculation_Modal_Shown;
                    }
                    else {
                        return false;
                    }

                },
                set(value) {
                    this.$store.dispatch('driveTechnologies/change_wrappingAngleFrameCalculation_Modal_Shown_storeAction', value)
                }
            },
            wrappingAngleFrameCalculation_Modal_Available: {
                get() {
                    if (this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade != null) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.frame_Available;
                    }
                    else {
                        return false;
                    }


                },
            },
            frameInformation_Show: {
                get() {
                    if (this.$store.state.driveTechnologies.frameInformation != null &&
                        this.$store.state.driveTechnologies.frameInformation.hasOwnProperty('status') == true &&
                        this.$store.state.driveTechnologies.frameInformation.status.hasOwnProperty('statusCode') == true &&
                        this.$store.state.driveTechnologies.frameInformation.status.statusCode.startsWith('76') == false &&
                        this.$store.state.driveTechnologies.frameInformation.status.statusCode.startsWith('20') == false &&
                        this.$store.state.driveTechnologies.frameInformation.status.hasOwnProperty('statusInformations') == true &&
                        this.$store.state.driveTechnologies.frameInformation.status.statusInformations != null &&
                        this.$store.state.driveTechnologies.frameInformation.status.statusInformations.length > 0) {

                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            frameInformation: {
                get() {
                    return this.$store.state.driveTechnologies.frameInformation;
                }
            },
            horizontalDistanceOfTheRopeHoles_Show: {
                get() {
                    if ((this.$store.state.driveTechnologies.frame_Data.hasOwnProperty('frame_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.frame_Data.frame_Selection))) {
                        const firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue = this.$store.state.driveTechnologies.frame_Data.frame_Selection.findIndex(i => i.selected == true);
                        if (firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue > - 1) {
                            this.$store.dispatch('driveTechnologies/change_frame_horizontalDistance_Minimum_storeAction', this.$store.state.driveTechnologies.frame_Data.frame_Selection[firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue].frame.horizontalDistanceMinimum);
                            this.$store.dispatch('driveTechnologies/change_frame_horizontalDistance_Maximum_storeAction', this.$store.state.driveTechnologies.frame_Data.frame_Selection[firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue].frame.horizontalDistanceMaximum);
                            if (this.$store.state.driveTechnologies.frame_Data.frame_Selection[firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue].frame.pulleyDiameter == 0) {
                                return false;
                            }
                            else {
                                this.$store.dispatch('driveTechnologies/check_frame_numberOfPulleys_storeAction', this.$store.state.driveTechnologies.frame_Data.frame_Selection[firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue].frame);
                                return true;
                            }
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        return false;
                    }

                }
            },
            frame_ImagePath: {
                get() {
                    if ((this.$store.state.driveTechnologies.frame_Data.hasOwnProperty('frame_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.frame_Data.frame_Selection))) {
                        const firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue = this.$store.state.driveTechnologies.frame_Data.frame_Selection.findIndex(i => i.selected == true);
                        if (firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue > - 1) {
                            return this.$store.state.driveTechnologies.frame_Data.frame_Selection[firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue].imagePath;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                }
            },
        },

        created() {
            this.changeWindowSize();
            if (this.configurationOverviewsId != null) {
                this.$store.dispatch('configurations/check_toDos_at_driveTechnologies_storeAction')
            }
            else {
                this.$store.dispatch('configurations/toDos_after_routeSlug_UpdatedOrEntered_storeAction', 'driveTechnologies')
            }
        },

        methods: {
            openHelpFile() {
                this.$store.dispatch('configurations/openDriveTechnologyHelpFile_storeAction', this.$store.state.configurations.language);
            },
            changeWindowSize() {
                const width = document.documentElement.clientWidth;
                if (width <= 1500) {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_Filter_Shown_storeAction', false);
                }
                else {
                    this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_Filter_Shown_storeAction', true);
                }
            },
            customizedColor(value) {
                if (value === true) return '#5575ab';
                else return '#00338e';
            },

            ValueDecimalSeparator(value) {
                if (value != null) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                }
                return value;
            },

            furtherToControlTechnologies() {
                router.push({ params: { slug: 'controlTechnologies' } });
            },


            // elevatorMotor_Machine
            async get_elevatorMotor_Machine_Informations() {
                await this.$store.dispatch('driveTechnologies/get_elevatorMotor_Machine_Informations_storeAction');
            },

            show_less_elevatorMotor_Machines_fromSelection() {
                if (this.elevatorMotor_Machine_Selection_lastIndex < 10) {
                    this.elevatorMotor_Machine_Selection_lastIndex = this.elevatorMotor_Machine_Selection_lastIndex - (this.elevatorMotor_Machine_Selection_lastIndex - 5);
                }
                else if (this.elevatorMotor_Machine_Selection_lastIndex >= 10) {
                    this.elevatorMotor_Machine_Selection_lastIndex = this.elevatorMotor_Machine_Selection_lastIndex - 5;
                }
            },
            show_full_elevatorMotor_Machine_Selection() {
                if (this.elevatorMotor_Machine_Selection_Filtered == false) {
                    this.elevatorMotor_Machine_Selection_lastIndex = this.elevatorMotor_Machine_Selection_fullLength;
                }
                else {
                    this.elevatorMotor_Machine_Selection_lastIndex = this.filtered_elevatorMotor_Machine_Selection.length;
                }
            },
            show_more_elevatorMotor_Machines_fromSelection() {
                this.elevatorMotor_Machine_Selection_lastIndex = this.elevatorMotor_Machine_Selection_lastIndex + 5;
                if (this.elevatorMotor_Machine_Selection_Filtered == false) {
                    if (this.elevatorMotor_Machine_Selection_lastIndex > this.elevatorMotor_Machine_Selection_fullLength) {
                        this.elevatorMotor_Machine_Selection_lastIndex = this.elevatorMotor_Machine_Selection_fullLength;
                    }
                }
                else {
                    if (this.elevatorMotor_Machine_Selection_lastIndex > this.filtered_elevatorMotor_Machine_Selection.length) {
                        this.elevatorMotor_Machine_Selection_lastIndex = this.filtered_elevatorMotor_Machine_Selection.length;
                    }
                }
            },
            async change_tractionCheck_Modal_Continue(value) {
                if (value == true) {
                    const firstIndex_whichMeetConditions = this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => (i.elevatorMotor_Machine.blockNumberMachine == this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == this.$store.state.driveTechnologies.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.blockNumber))
                    await this.$store.dispatch('driveTechnologies/change_tractionCheck_Modal_Continue_storeAction', false);
                    if (firstIndex_whichMeetConditions > -1) {
                        const motor = this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[firstIndex_whichMeetConditions];
                        this.change_Selected_at_elevatorMotor_Machine_Selection(motor);
                    }
                }

            },
            change_Selected_at_elevatorMotor_Machine_Selection(value) {
                this.$store.dispatch('driveTechnologies/checkIf_tractionCheck_Passed_storeAction', value);
            },


            // elevatorMotor_Machine filter
            change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection(elevatorMotor_Machine_Series) {
                this.$store.dispatch('driveTechnologies/change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_storeAction', elevatorMotor_Machine_Series);
            },

            remove_elevatorMotor_Machine_ElectricalUtilization_Maximum_and_MechanicalUtilization_Maximum_at_arrayof_active_elevatorMotor_Machine_FilterCriteria(value) {
                this.$store.dispatch('driveTechnologies/remove_elevatorMotor_Machine_ElectricalUtilization_Maximum_and_MechanicalUtilization_Maximum_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', value)
            },
            remove_elevatorMotor_Machine_TractionSheave_Diameter_at_arrayof_active_elevatorMotor_Machine_FilterCriteria() {
                this.$store.dispatch('driveTechnologies/remove_elevatorMotor_Machine_TractionSheave_Diameter_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction')
            },
            addOrRemove_elementAt_arrayof_active_elevatorMotor_Machine_FilterCriteria(value) {
                this.$store.dispatch('driveTechnologies/addOrRemove_elementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', value)
            },
            remove_allElementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria() {
                this.$store.dispatch('driveTechnologies/remove_allElementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction')
            },


            // wrappingAngleCalculation & tractionCheck
            checkIf_wrappingAngle_hasToBeCalculated_before_tractionCheck(value) {
                this.$store.dispatch('driveTechnologies/checkIf_wrappingAngle_withFrame_hasToBeCalculated_before_tractionCheck_storeAction', value)
            },
            checkIf_wrappingAngle_hasToBeCalculated_before_tractionCheck_with_Data_fromItemOf_elevatorMotor_Machine_Selection(value) {
                this.$store.dispatch('driveTechnologies/checkIf_wrappingAngle_withFrame_storeAction', value)
                this.$store.dispatch('driveTechnologies/change_elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade_storeAction', value);
                this.$store.dispatch('driveTechnologies/checkIf_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded2_storeAction')
            },

            // wrappingAngleCalculation
            get_wrappingAngleCalculation_Object_fromItemOf_elevatorMotor_Machine_Selection(selected_elevatorMotor_Machine) {
                this.$store.dispatch('driveTechnologies/get_wrappingAngleCalculation_Object_fromItemOf_elevatorMotor_Machine_Selection_storeAction', selected_elevatorMotor_Machine)
                this.$store.dispatch('driveTechnologies/change_wrappingAngleCalculation_Modal_Shown_storeAction', true)
            },

            change_wrappingAngle_Calculated(value) {
                this.$store.dispatch('driveTechnologies/change_wrappingAngle_Calculated_storeAction', value)
            },
            show_tractionCheck_Modal() {
                this.$store.dispatch('driveTechnologies/show_tractionCheck_Modal_storeAction')
            },

            // tractionCheck
            get_tractionCheck_Data_fromItemOf_elevatorMotor_Machine_Selection(selected_elevatorMotor_Machine) {
                this.$store.dispatch('driveTechnologies/get_tractionCheck_Data_fromItemOf_elevatorMotor_Machine_Selection_storeAction', selected_elevatorMotor_Machine)
                this.$store.dispatch('driveTechnologies/change_tractionCheck_Modal_Shown_storeAction', true)
            },

            backTo_wrappingAngle_Modal() {
                this.$store.dispatch('driveTechnologies/backTo_wrappingAngle_Modal_storeAction');
            },


            // elevatorMotor_Machine_TractionSheaveSide
            change_Selected_at_elevatorMotor_Machine_TractionSheaveSide_Selection(elevatorMotor_Machine_TractionSheaveSide) {
                if (elevatorMotor_Machine_TractionSheaveSide.elevatorMotor_Machine_TractionSheaveSide.text != this.selected_elevatorMotor_Machine_TractionSheaveSide.elevatorMotor_Machine_TractionSheaveSide.text) {
                    const firstIndex_whichMeetCondition = this.elevatorMotor_Machine_TractionSheaveSide_Selection.findIndex(i => i.elevatorMotor_Machine_TractionSheaveSide.text == elevatorMotor_Machine_TractionSheaveSide.elevatorMotor_Machine_TractionSheaveSide.text);
                    if (firstIndex_whichMeetCondition > -1) {
                        this.$store.dispatch('driveTechnologies/checkIf_changesAt_elevatorMotor_Machine_TractionSheaveSide_changes_configurationFlow_storeAction', firstIndex_whichMeetCondition);
                    }
                    else {
                        // error
                    }
                }
            },


            // elvatorMotor_Machine_InstallationPosition
            change_Selected_at_elevatorMotor_Machine_InstallationPosition_Selection(elevatorMotor_Machine_InstallationPosition) {
                if (elevatorMotor_Machine_InstallationPosition.elevatorMotor_Machine_InstallationPosition.text != this.selected_elevatorMotor_Machine_InstallationPosition.elevatorMotor_Machine_InstallationPosition.text) {
                    const firstIndex_whichMeetCondition = this.elevatorMotor_Machine_InstallationPosition_Selection.findIndex(i => i.elevatorMotor_Machine_InstallationPosition.text == elevatorMotor_Machine_InstallationPosition.elevatorMotor_Machine_InstallationPosition.text);
                    if (firstIndex_whichMeetCondition > -1) {
                        this.$store.dispatch('driveTechnologies/checkIf_changesAt_elevatorMotor_Machine_InstallationPosition_changes_configurationFlow_storeAction', firstIndex_whichMeetCondition);
                    }
                    else {
                        // error
                    }
                }
            },


            // elevatorMotor_TemperatureMonitoring
            change_Selected_at_elevatorMotor_TemperatureMonitoring_Selection(elevatorMotor_TemperatureMonitoring) {
                if (elevatorMotor_TemperatureMonitoring.elevatorMotor_TemperatureMonitoring.typeDesignation != this.selected_elevatorMotor_TemperatureMonitoring.elevatorMotor_TemperatureMonitoring.typeDesignation) {
                    const firstIndex_whichMeetCondition = this.elevatorMotor_TemperatureMonitoring_Selection.findIndex(i => i.elevatorMotor_TemperatureMonitoring.typeDesignation == elevatorMotor_TemperatureMonitoring.elevatorMotor_TemperatureMonitoring.typeDesignation);
                    if (firstIndex_whichMeetCondition > -1) {
                        this.$store.dispatch('driveTechnologies/checkIf_changesAt_elevatorMotor_TemperatureMonitoring_changes_configurationFlow_storeAction', firstIndex_whichMeetCondition);
                    }
                    else {
                        // error
                    }
                }
            },


            // elevatorMotor_ForcedVentilation
            change_Selected_at_elevatorMotor_ForcedVentilation(value) {
                if (value != this.elevatorMotor_ForcedVentilation.selected) {
                    this.$store.dispatch('driveTechnologies/checkIf_changesAt_elevatorMotor_ForcedVentilation_changes_configurationFlow_storeAction', value);
                }
            },


            // elevatorMotor_Brake
            // elevatorMotor_Brake_Voltage
            change_Selected_at_elevatorMotor_Brake_Voltage_Selection(elevatorMotor_Brake_Voltage) {
                if (elevatorMotor_Brake_Voltage.nominalVoltage != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.nominalVoltage) {
                    this.$store.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Voltage_storeAction', elevatorMotor_Brake_Voltage.nominalVoltage);
                }
            },
            // elevatorMotor_Brake_MechanicalRelease
            change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection(elevatorMotor_Brake_MechanicalRelease) {
                if (elevatorMotor_Brake_MechanicalRelease.text != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.mechanicalRelease.text) {
                    this.$store.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_MechanicalRelease_storeAction', elevatorMotor_Brake_MechanicalRelease.text);
                }
            },
            // elevatorMotor_Brake_ReleaseMonitoring
            change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection(elevatorMotor_Brake_ReleaseMonitoring) {
                if (elevatorMotor_Brake_ReleaseMonitoring.text != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.releaseMonitoring.text) {
                    this.$store.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_ReleaseMonitoring_storeAction', elevatorMotor_Brake_ReleaseMonitoring.text);
                }
            },
            // elevatorMotor_Brake_Connection
            change_Selected_at_elevatorMotor_Brake_Connection_Selection(elevatorMotor_Brake_Connection) {
                if (this.selected_elevatorMotor_Brake.elevatorMotor_Brake.hasOwnProperty('brakeConnectionTypeDesignation') == false || elevatorMotor_Brake_Connection.text != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.brakeConnectionTypeDesignation) {
                    this.$store.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Connection_storeAction', elevatorMotor_Brake_Connection);
                }
            },
            // elevatorMotor_Brake_Bowdenwire
            change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection(elevatorMotor_Brake_DeliverBowdenwire) {
                if (this.selected_elevatorMotor_Brake.elevatorMotor_Brake.hasOwnProperty('bowdenwireDeliver') == false || this.selected_elevatorMotor_Brake.elevatorMotor_Brake.bowdenwireDeliver == null || elevatorMotor_Brake_DeliverBowdenwire.text != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.bowdenwireDeliver) {
                    this.$store.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_DeliverBowdenwire_storeAction', elevatorMotor_Brake_DeliverBowdenwire);
                }
            },
            change_Selected_at_elevatorMotor_Brake_LengthBowdenwire_Selection(elevatorMotor_Brake_LengthBowdenwire) {
                if (this.selected_elevatorMotor_Brake.elevatorMotor_Brake.hasOwnProperty('bowdenwireLength') == false || this.selected_elevatorMotor_Brake.elevatorMotor_Brake.bowdenwireLength == null || elevatorMotor_Brake_LengthBowdenwire != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.bowdenwireLength) {
                    this.$store.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Bowdenwire_storeAction', elevatorMotor_Brake_LengthBowdenwire);
                }
            },

            // elevatorMotor_Encoder
            change_Selected_at_elevatorMotor_Encoder_Selection(elevatorMotor_Encoder) {
                if (elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation != this.selected_elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation) {
                    const firstIndex_whichMeetCondition = this.elevatorMotor_Encoder_Selection.findIndex(i => i.elevatorMotor_Encoder.typeDesignation == elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation);
                    if (firstIndex_whichMeetCondition > -1) {
                        this.$store.dispatch('driveTechnologies/checkIf_changesAt_elevatorMotor_Encoder_changes_configurationFlow_storeAction', firstIndex_whichMeetCondition);
                    }
                    else {
                        // error
                    }
                }
            },

            // frame
            change_Selected_at_frame_Selection(value) {
                this.$store.dispatch('driveTechnologies/checkIf_changesAt_frame_changes_configurationFlow_storeAction', value);
            },
            change_frame_Calculated_Show(value) {
                this.$store.dispatch('driveTechnologies/change_wrappingAngleFrameCalculation_Modal_Shown_storeAction', value)
            },

            //back_to_input() {
            //    router.push({ params: { slug: 'inputs' } });
            //},
        },

        mounted() {
            window.addEventListener('resize', this.changeWindowSize);
        },
        unmounted() {
            window.removeEventListener('resize', this.changeWindowSize);
        },
    }

</script>

<style>
    .my_elevatorMotor_TemperatureMonitoring {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_TemperatureMonitoring li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_TemperatureMonitoring li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_TemperatureMonitoring li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_TemperatureMonitoring .active {
            border: 2px solid blue;
        }

    .my_elevatorMotor_Brake_Voltage_Selection {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Brake_Voltage_Selection li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Brake_Voltage_Selection li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Brake_Voltage_Selection li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Brake_Voltage_Selection .active {
            border: 2px solid blue;
        }

    .my_elevatorMotor_Brake_MechanicalRelease_Selection {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Brake_MechanicalRelease_Selection li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Brake_MechanicalRelease_Selection li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Brake_MechanicalRelease_Selection li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Brake_MechanicalRelease_Selection .active {
            border: 2px solid blue;
        }

        .my_elevatorMotor_Brake_MechanicalRelease_Selection .disabled {
            background-color: #ced2d8;
            pointer-events: none;
        }

    .my_elevatorMotor_Brake_ReleaseMonitoring_Selection {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Brake_ReleaseMonitoring_Selection li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Brake_ReleaseMonitoring_Selection li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Brake_ReleaseMonitoring_Selection li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Brake_ReleaseMonitoring_Selection .active {
            border: 2px solid blue;
        }

        .my_elevatorMotor_Brake_ReleaseMonitoring_Selection .disabled {
            background-color: #ced2d8;
            pointer-events: none;
        }

    .my_elevatorMotor_Brake_Connection_Selection {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Brake_Connection_Selection li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Brake_Connection_Selection li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Brake_Connection_Selection li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Brake_Connection_Selection .active {
            border: 2px solid blue;
        }

    .my_elevatorMotor_Brake_Bowdenwire_Selection {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Brake_Bowdenwire_Selection li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Brake_Bowdenwire_Selection li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Brake_Bowdenwire_Selection li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Brake_Bowdenwire_Selection .active {
            border: 2px solid blue;
        }

    .my_elevatorMotor_Encoder {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Encoder li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Encoder li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Encoder li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Encoder .active {
            border: 2px solid blue;
        }

    .my_elevatorMotor_Machine_filter {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Machine_filter li {
            border: 2px solid #00338e;
            color: #00338e;
            display: inline-block;
            padding: 9px;
            width: 50%;
            text-align: center;
            font-weight: 600;
        }

        .my_elevatorMotor_Machine_filter .active {
            background-color: #00338e;
            color: #ffffff;
        }


    #my_engine_table_images_ZAproducts {
        height: 70px !important;
    }

    #my_engine_variables {
        margin-top: 15px;
    }

    #my_engine_table_parameters_slash {
        font-size: 1.0rem;
    }

    .my_table_parameters_infoText {
        font-size: 0.9rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        width: calc(100%);
    }

        .my_table_parameters_infoText:hover {
            text-overflow: clip;
            white-space: normal;
            word-break: break-word;
        }
</style>
